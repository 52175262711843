import React, { useState, useEffect, useContext, useRef } from "react"

import "./AddTestimonials.css"
import axios from "axios"

import { DropzoneDialog } from "material-ui-dropzone"
import Button from "@material-ui/core/Button"

import IconButton from "@material-ui/core/IconButton"

import CloseIcon from "@material-ui/icons/Close"
import SideMenu from "./../SideMenu/SideMenu"
import Header from "./../Header/Header"
import { TokenContext } from "./../../context/TokenContext"
import { useNavigate, useParams } from "react-router-dom"
import VideoRecorder from "react-video-recorder"
import { CircularProgress } from "@material-ui/core"

const AddTestimonials = () => {
  const navigation = useNavigate()
  const [state, setState] = useState("")
  const [email, setEmail] = useState("")
  const [companyEmail, setCompanyEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [company, setCompany] = useState("")
  const [loading, setLoading] = useState(false)
  const [vidOn, setVidOn] = useState(false)
  const recordRef = useRef(null)
  const [vidLoader, setVidLoader] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  const {
    user,
    setUser,
    token,
    setToken,
    nameInfo,
    setNameInfo,
    globalRole,
    setGlobalRole
  } = useContext(TokenContext)
  const [vid, setVid] = useState("")
  const [open, setOpen] = useState(false)
  console.log(`the token is ${localStorage.getItem("@storage_Key")}`)

  const { id, testid, userid } = useParams()

  console.log("the loader is", vidLoader)

  // useEffect(() => {
  //   getProfileInfo()
  // }, [])

  const getProfileInfo = () => {
    var secondConfig = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/users/api/profile/",
      headers: {
        "Content-Type": "application/json"
      }
    }
    axios(secondConfig)
      .then(response => {
        console.log(response.data)
        if (response.data.role === "" || response.data.role === "string") {
          setGlobalRole("user")
          localStorage.setItem("@storage_Role", "user")
        } else {
          setGlobalRole(response.data.role)
          localStorage.setItem("@storage_Role", response.data.role)
        }
        setNameInfo(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // useEffect(() => {
  //   console.log(JSON.parse(localStorage.getItem("@storage_Object")))
  //   const getData = () => {
  //     try {
  //       console.log("effect check")
  //       const value = localStorage.getItem("@storage_Key")
  //       const user_value = localStorage.getItem("@storage_Object")
  //       const user_role = localStorage.getItem("@storage_Role")
  //       console.log(`the value of token is ${value}`)
  //       if (value !== null || user_value !== null) {
  //         // console.log("Token: " + value)
  //         // console.log("User Object: " + JSON.parse(user_value).id)
  //         setToken(value)
  //         setUser(JSON.parse(user_value))
  //         setGlobalRole(user_role)
  //       } else {
  //         setToken("")
  //         setUser({})
  //         navigation("/sign-in")
  //       }
  //     } catch (e) {}
  //   }
  //   getData()
  // }, [])

  const handleChange = files => {
    console.log(files[0])

    if (files[0] === undefined) {
      return
    }

    setVidLoader(true)

    const data = new FormData()

    data.append("document", files[0])

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/tUploader/api/tUploader/",
      headers: {
        "content-type": "multipart/form-data"
      },
      data: data
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.document.split("?")[0])
        setVid(resp.data.document.split("?")[0])
        setVidLoader(false)
      })
      .catch(err => {
        console.log(err.message)
        alert("Failed to upload video")
        setVidLoader(false)
      })
  }

  // useEffect(() => {
  //   getCampaign()
  // }, [])

  const getCampaign = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/campaign/api/campaign/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)
        setCampaigns(resp.data.results)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }

  const postTestimonial = () => {
    if (vid === "") {
      alert(
        "Please upload a testimonial Video, or wait for it to load before submitting testimonial"
      )
      return
    }

    if (email === "" || customerName === "" || phone === "") {
      alert("Please fill all the fields")
      return
    }

    setLoading(true)

    var today = new Date()
    var dd = String(today.getDate()).padStart(2, "0")
    var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = today.getFullYear()

    today = mm + "/" + dd + "/" + yyyy

    let JSONed = JSON.stringify({
      email: email,
      video: vid,
      CustomerName: customerName,
      CustomerPhone: phone,
      usedID: userid,
      campaignID: id,
      status: true,
      createdAt: today
    })

    let JSONed2 = JSON.stringify({
      time: today,
      title: "A Testimonial is received",
      description: `The testimonial for your campaign ${email} is received from ${customerName}`,
      userID: userid || 1,
      inChecked: false
    })

    console.log(JSONed)
    var config2 = {
      method: "POST",
      url: `https://ready-shout-30574.botics.co/notifications/api/notifications/`,
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed2
    }

    var config = {
      method: "PATCH",
      url: `https://ready-shout-30574.botics.co/testimonials/api/testimonials/${testid}/`,
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)

        axios(config2)
          .then(newResp => {
            alert("Testimonial sent successfully")

            setLoading(false)
            window.location.reload(false)
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err.message)
        setLoading(false)
        alert(
          "An error occured, The link may be broken or the campaign no longer exists"
        )
      })
  }

  const dialogTitle = () => (
    <>
      <span style={{ fontSize: "16px" }}>
        Upload Video (make sure the title is less than 30 characters)
      </span>
      <IconButton
        style={{ right: "12px", top: "8px", position: "absolute" }}
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </IconButton>
    </>
  )

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          alignItems: "flex-start",
          justifyItems: "center",
          background: "#3b3c3a",
          minHeight: "100vh",
          minWidth: "800px",
          paddingLeft: "10%"
        }}
      >
        <p
          style={{
            marginTop: "40px",
            color: "white",
            fontSize: "26px",
            fontWeight: "bold",
            fontFamily: "Work Sans",
            cursor: "pointer"
          }}
        >
          {"Add Testimonial"}
        </p>

        {vid === "" ? (
          <div>
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              style={{
                marginBottom: "20px",
                backgroundColor: "#57b100",
                color: "white",

                fontSize: "12px"
              }}
            >
              Add Video
            </Button>
            <Button
              variant="contained"
              onClick={() => recordRef.current.click()}
              style={{
                marginBottom: "20px",
                backgroundColor: "#57b100",
                color: "white",
                marginLeft: "15px",
                fontSize: "12px"
              }}
            >
              Camera (Mobile Only)
            </Button>
            <input
              style={{ opacity: 0 }}
              ref={recordRef}
              type="file"
              accept="video/*"
              capture="user"
              onChange={files => handleChange(files.target.files)}
            />
            <DropzoneDialog
              dialogTitle={dialogTitle()}
              showPreviews={true}
              showFileNamesInPreview={true}
              cancelButtonText={"cancel"}
              submitButtonText={"Submit"}
              maxFileSize={100000000}
              acceptedFiles={["video/mp4"]}
              showPreviewsInDropzone={true}
              filesLimit={1}
              open={open}
              onClose={() => setOpen(false)}
              onSave={files => {
                console.log("Files:", files)
                handleChange(files)
                setOpen(false)
              }}
            />
          </div>
        ) : vidLoader ? (
          <>
            <CircularProgress
              color="white"
              style={{ color: "white", marginBottom: "15px" }}
            />
            <p style={{ color: "white" }}>
              The Video is being uploaded, please wait
            </p>
          </>
        ) : (
          <embed
            type="video/webm"
            src={vid}
            width="200"
            height="100"
            style={{ padding: "20px", resize: "cover" }}
          />
        )}
        {/* <select
        id="campaigns"
        name="Campaigns"
        onChange={e => setState(e.target.value)}
        className="input-sign-new"
      >
        <option value="">Select Campaign</option>
        {campaigns.map(states => (
          <option key={states.id} value={states.id}>
            {states.VidName}
          </option>
        ))}
      </select> */}
        {/* <input
              type="text"
              placeholder="Select a Campaign"
              className="input-sign-new"
              style={{ marginTop: "40px" }}
            /> */}
        <p style={{ color: "white", marginTop: "-3px" }}>
          {" "}
          Please input your details below to add a testimonial.
        </p>
        <input
          type="text"
          placeholder="* Your Email"
          className="input-sign-new"
          style={{ width: "40%", height: "45px" }}
          onChange={e => setEmail(e.target.value)}
        />
        {/* <input
        type="text"
        placeholder="Company Name"
        className="input-sign-new"
        style={{ width: "40%",height:'45px' }}
        onChange={e => setCompany(e.target.value)}
      /> */}
        <input
          type="text"
          placeholder="* Customer Name"
          className="input-sign-new"
          style={{ width: "40%", height: "45px" }}
          onChange={e => setCustomerName(e.target.value)}
        />
        {/* <input
        type="text"
        placeholder="Company Email"
        className="input-sign-new"
        style={{ width: "40%",height:'45px' }}
        onChange={e => setCompanyEmail(e.target.value)}
      /> */}
        <input
          type="text"
          placeholder="* Phone Number"
          className="input-sign-new"
          style={{ width: "40%", height: "45px" }}
          onChange={e => setPhone(e.target.value)}
        />

        <button
          className="login-btn-small"
          style={{ width: "40%", height: "45px" }}
          onClick={() => {
            postTestimonial()
          }}
          disabled={loading}
        >
          {`${loading ? "Loading ..." : "Send Testimonial"}`}
        </button>
      </div>
      {vidOn ? (
        <VideoRecorder
          onRecordingComplete={videoBlob => {
            // Do something with the video...
            console.log("videoBlob", videoBlob)
            setVidOn(false)
          }}
        />
      ) : null}
    </>
  )
}

export default AddTestimonials
