import React from "react"

// import { render } from "react-dom";
import Landing from "../../frontend/src/screens/Landing"
import Signup from "./screens/Signup"
import Signin from "./screens/Signin"
import PasswordReset from "./screens/Passwordreset"
import TockenInput from "./screens/TockenInput"
import UpdatePassword from "./screens/UpdatePassword"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navigation from "./navigation/navigation"
import { MenuProvider } from "./context/MenuContext"
import { TokenProvider } from "./context/TokenContext"
import { NotificationProvider } from "./context/NotificationContext"
import {
  ResetTokenContext,
  ResetTokenProvider
} from "./context/ResetTokenContext"

import "./App.css"
import UserProfile from "./services/AuthCheckerService"
import { useEffect } from "react"
import { Detector } from "react-detect-offline"
import AuthVerify from "./common/auth-verify"

function App() {
  //test comment

  return (
    <div className="main-root">
      {/* <Detector
          render={({ online }) => (
            <div className={`App ${online ? "Online" : "Offline"}`}>
              {online ? "Online" : "Offline"}
            </div>
          )}
        /> */}
      <Router>
        <NotificationProvider>
          <TokenProvider>
            <ResetTokenProvider>
              <MenuProvider>
                <Navigation />
                {/* <AuthVerify logOut={logOut} /> */}
              </MenuProvider>
            </ResetTokenProvider>
          </TokenProvider>
        </NotificationProvider>
      </Router>
    </div>
  )
}

export default App
