import React, { useState, useEffect, useContext } from "react"
import "./MyProfile.css"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import { TokenContext } from "../../context/TokenContext"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import PopUp from "../Components/PopUp"

const MyProfile = () => {
  const { token, setToken, user, setUser } = useContext(TokenContext)
  const [profileInfo, setProfileInfo] = useState({})
  const [googleName, setGoogleName] = useState(null)
  const [successPopUp, setSuccessPopUp] = useState(false)
  const [errorPopUp, setErrorPopUp] = useState(false)
  const navigation = useNavigate()

  useEffect(() => {
    let name = localStorage.getItem("@google_Name")
    setGoogleName(name)
  }, [])

  console.log(user)
  // console.log(localStorage.getItem("@storage_Key"))

  useEffect(() => {
    if (token === "" || token === undefined || token === null) {
      navigation("/sign-in")
    }
  }, [])

  useEffect(() => {
    getProfileInfo()
  }, [])

  console.log(profileInfo)

  console.log(`the storage key is ${localStorage.getItem("@storage_Key")}`)

  const getProfileInfo = () => {
    var secondConfig = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/users/api/profile/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }
    axios(secondConfig)
      .then(response => {
        console.log(response.data)
        setProfileInfo(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const patchProfileInfo = () => {
    let upgradedProfileInfo = { ...profileInfo }

    if (profileInfo.FName === "") {
      upgradedProfileInfo.FName = "string"
    }

    if (profileInfo.role === "") {
      upgradedProfileInfo.role = "string"
    }

    if (profileInfo.name === "" || profileInfo.name === null) {
      upgradedProfileInfo.name = "string"
    }

    if (profileInfo.city === "") {
      upgradedProfileInfo.city = "string"
    }
    if (profileInfo.phone === "") {
      upgradedProfileInfo.phone = "string"
    }

    if (profileInfo.LName === "") {
      upgradedProfileInfo.LName = "string"
    }

    if (profileInfo.CompanyName === "") {
      upgradedProfileInfo.CompanyName = "string"
    }

    if (profileInfo.country === "") {
      upgradedProfileInfo.country = "string"
    }

    if (profileInfo.state === "") {
      upgradedProfileInfo.state = "string"
    }
    if (profileInfo.zip === "") {
      upgradedProfileInfo.zip = "string"
    }
    if (profileInfo.PrimaryAddress === "") {
      upgradedProfileInfo.PrimaryAddress = "string"
    }
    if (profileInfo.SecondaryAddress === "") {
      upgradedProfileInfo.SecondaryAddress = "string"
    }

    console.log(upgradedProfileInfo)

    let newJSON = JSON.stringify(upgradedProfileInfo)
    var secondConfig = {
      method: "PATCH",
      url: "https://ready-shout-30574.botics.co/users/api/profile/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },

      data: newJSON
    }
    axios(secondConfig)
      .then(response => {
        console.log(response.data)
        setSuccessPopUp(true)
      })
      .catch(err => {
        console.log(err)
        setErrorPopUp(true)
      })
  }

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <p
            style={{
              width: "90%",
              marginTop: "40px",
              color: "white",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Work Sans"
            }}
          >
            {`< My Profile`}
          </p>

          <div className="settings-main-low">
            <div className="profile-left">
              <p style={{ color: "white", fontSize: "20px" }}>
                Personal Information
              </p>

              <input
                type="text"
                placeholder="First Name"
                className="input-sign"
                defaultValue={
                  profileInfo.FName === "string"
                    ? ""
                    : profileInfo.FName !== "" ||
                      profileInfo.FName !== null ||
                      profileInfo.FName !== undefined
                    ? profileInfo.FName
                    : googleName !== null ||
                      googleName !== "" ||
                      googleName !== undefined
                    ? googleName
                    : ""
                }
                onChange={e =>
                  setProfileInfo({ ...profileInfo, FName: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Last Name"
                className="input-sign"
                defaultValue={
                  profileInfo.LName === "string" ? "" : profileInfo.LName
                }
                onChange={e =>
                  setProfileInfo({ ...profileInfo, LName: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Company Name"
                className="input-sign"
                defaultValue={profileInfo.CompanyName}
                onChange={e =>
                  setProfileInfo({
                    ...profileInfo,
                    CompanyName: e.target.value
                  })
                }
              />
              <input
                type="text"
                placeholder="Email Address"
                className="input-sign"
                disabled={true}
                defaultValue={
                  JSON.parse(localStorage.getItem("@storage_Object")).email
                }
              />
              <input
                type="text"
                placeholder="Phone Number"
                className="input-sign"
                defaultValue={profileInfo.phone}
                onChange={e =>
                  setProfileInfo({ ...profileInfo, phone: e.target.value })
                }
              />

              <p style={{ color: "white", fontSize: "20px" }}>
                Address Details
              </p>
              <input
                type="text"
                placeholder="Primary Address"
                className="input-sign"
                defaultValue={
                  profileInfo.PrimaryAddress === "string"
                    ? ""
                    : profileInfo.PrimaryAddress
                }
                onChange={e =>
                  setProfileInfo({
                    ...profileInfo,
                    PrimaryAddress: e.target.value
                  })
                }
              />
              <input
                type="text"
                placeholder="Secondary Address"
                className="input-sign"
                defaultValue={
                  profileInfo.SecondaryAddress === "string"
                    ? ""
                    : profileInfo.SecondaryAddress
                }
                onChange={e =>
                  setProfileInfo({
                    ...profileInfo,
                    SecondaryAddress: e.target.value
                  })
                }
              />
            </div>

            <div className="profile-right">
              <input
                type="text"
                placeholder="State"
                className="input-sign"
                defaultValue={
                  profileInfo.state === "string"
                    ? ""
                    : profileInfo.SecondaryAddress
                }
                onChange={e =>
                  setProfileInfo({ ...profileInfo, state: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Country"
                className="input-sign"
                defaultValue={
                  profileInfo.country === "string" ? "" : profileInfo.country
                }
                onChange={e =>
                  setProfileInfo({ ...profileInfo, country: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="City"
                className="input-sign"
                defaultValue={
                  profileInfo.city === "string" ? "" : profileInfo.city
                }
                onChange={e =>
                  setProfileInfo({ ...profileInfo, city: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Zip-code"
                className="input-sign"
                disabled={profileInfo.zip !== "string" || profileInfo.zip !== ""}
                defaultValue={
                  profileInfo.zip === "string" ? "" : profileInfo.zip
                }
                onChange={e =>
                  setProfileInfo({ ...profileInfo, zip: e.target.value })
                }
              />

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#57b100",
                  color: "#57b100",
                  width: "99%",
                  height: "40px",
                  borderRadius: 5,
                  marginBottom: 10,
                  background: "none",
                  border: "2px solid #57b100"
                }}
              >
                <p>Edit</p>
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#57b100",
                  color: "white",
                  width: "100%",
                  height: "40px",
                  borderRadius: 5,
                  cursor: "pointer"
                }}
                onClick={patchProfileInfo}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
        </div>

        {successPopUp &&

        <PopUp
          open={successPopUp}
          handleClose={()=> setSuccessPopUp(false)}
          title={"Success"}
          text={"Profile Updated Successfully"}
          okText={"ok"}
          okAction={()=> setSuccessPopUp(false)}
        />}

        {
          errorPopUp &&
          <PopUp
          open={errorPopUp}
          handleClose={()=> setErrorPopUp(false)}
          title={"Failed"}
          text={"Failed to update your profile, please check your internet connection or the server might be down"}
          okText={"ok"}
          okAction={()=> setErrorPopUp(false)}
        />

        }

      </div>
    </>
  )
}

export default MyProfile
