import React, { useContext, useState, useEffect } from "react"
import "./SideMenu.css"
import Menu from "./../../assets/images/menu.png"
// import MenuGreen from "./../assets/images/menugreen.png"
import HomeImg from "./../../assets/images/home.png"
import MicImg from "./../../assets/images/mic.png"
import MicGreenImg from "./../../assets/images/micgreen.png"
import MsgImg from "./../../assets/images/Subtract.png"
import MsgImgGreen from "./../../assets/images/messagegreen.png"
import ContactsImg from "./../../assets/images/contacts.png"
import ContactsGreenImg from "./../../assets/images/bookgreen.png"
import PeopleImg from "./../../assets/images/people.png"
import ContactImg from "./../../assets/images/contact.png"
import StaffGreenImg from "./../../assets/images/peopleg.png"
import PeopleGreenImg from "./../../assets/images/lastg.png"
import CouponImg from "./../../assets/images/cwhite.png"
import CouponGreenImg from "./../../assets/images/cgreen.png"
import SettingsImg from "./../../assets/images/Settings.png"
import SettingsGreenImg from "./../../assets/images/settingsgreen.png"
import PerformanceImg from "./../../assets/images/performancewhite.png"
import PerformanceGreenImg from "./../../assets/images/performancegreen.png"
import RShoutImg from "./../../assets/images/readyshout.png"
import { useNavigate } from "react-router-dom"
import { MenuContext } from "../../context/MenuContext"
import { TokenContext } from "../../context/TokenContext"

const SideMenu = () => {
  const { menu, setMenu } = useContext(MenuContext)

  const { user, setUser, token, setToken } = useContext(TokenContext)

  const navigation = useNavigate()

  const [toggle, setToggle] = useState(true)

  // useEffect(() => {
  //   if (token === "" || token === undefined || token === null) {
  //     navigation("/sign-in")
  //   }
  // }, [])

  return (
    <>
      <div className="side-main">
        <div className="side-menu" onClick={() => setToggle(!toggle)}>
          <img src={Menu} className="side-icon" style={{ cursor: "pointer" }} />
        </div>

        <div className="side-middle">
          {/* {menu === "home" ? (
            <img
              src={HomeImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigation("/home")
              }}
            />
          ) : (
            <img
              src={HomeImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMenu("home")
                navigation("/home")
              }}
            />
          )} */}

          {menu === "campaign" ? (
            <img
              src={MicGreenImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigation("/campaigns")
              }}
            />
          ) : (
            <img
              src={MicImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMenu("campaign")
                navigation("/campaigns")
              }}
            />
          )}

          {menu === "test" ? (
            <img
              src={MsgImgGreen}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigation("/testimonials")
              }}
            />
          ) : (
            <img
              src={MsgImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMenu("test")
                navigation("/testimonials")
              }}
            />
          )}

          {menu === "contacts" ? (
            <img
              src={ContactsGreenImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigation("/contacts")
              }}
            />
          ) : (
            <img
              onClick={() => {
                navigation("/contacts")
                setMenu("contacts")
              }}
              src={ContactsImg}
              style={{ cursor: "pointer" }}
            />
          )}

          {menu === "staff" ? (
            <img
              src={StaffGreenImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigation("/staff-members")
              }}
            />
          ) : (
            <img
              onClick={() => {
                navigation("/staff-members")
                setMenu("contacts")
              }}
              src={PeopleImg}
              style={{ cursor: "pointer" }}
            />
          )}

          {menu === "profile" ? (
            <img
              src={PeopleGreenImg}
              style={{ cursor: "pointer" }}
              onClick={() => navigation("/my-profile")}
            />
          ) : (
            <img
              src={ContactImg}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMenu("profile")
                navigation("/my-profile")
              }}
            />
          )}
          {menu === "coupons" ? (
            <img
              src={CouponGreenImg}
              style={{ cursor: "pointer", height: "28px", width: "28px" }}
              onClick={() => navigation("/coupons")}
            />
          ) : (
            <img
              src={CouponImg}
              style={{ cursor: "pointer", height: "28px", width: "28px" }}
              onClick={() => {
                setMenu("profile")
                navigation("/coupons")
              }}
            />
          )}

          {menu === "performance" ? (
            <img
              src={PerformanceGreenImg}
              style={{ cursor: "pointer", height: "25px", width: "25px" }}
              onClick={() => navigation("/performance")}
            />
          ) : (
            <img
              src={PerformanceImg}
              style={{ cursor: "pointer", height: "25px", width: "25px" }}
              onClick={() => {
                setMenu("performance")
                navigation("/performance")
              }}
            />
          )}

          {menu === "settings" ? (
            <img
              onClick={() => navigation("/settings")}
              src={SettingsGreenImg}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <img
              onClick={() => {
                setMenu("settings")
                navigation("/settings")
              }}
              src={SettingsImg}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      {toggle ? (
        <div className="side-menu-full">
          <div className="side-menu" onClick={() => setToggle(!toggle)}>
            <img src={RShoutImg} style={{ marginLeft: "70px" }} />
          </div>

          <div className="side-middle-full">
            {/* {menu === "home" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/home")}
              >
                Home
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("home")
                  navigation("/home")
                }}
              >
                Home
              </p>
            )} */}
            {menu === "campaign" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/campaigns")}
              >
                My Campaigns
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("campaign")
                  navigation("/campaigns")
                }}
              >
                My Campaigns
              </p>
            )}

            {menu === "test" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/testimonials")}
              >
                Testimonials
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("test")
                  navigation("/testimonials")
                }}
              >
                Testimonials
              </p>
            )}

            {menu === "contacts" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/contacts")}
              >
                Contacts
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("contacts")
                  navigation("/contacts")
                }}
              >
                Contacts
              </p>
            )}

            {menu === "staff" ? (
              <p style={{ cursor: "pointer", color: "#57b100" }}>
                Staff Members
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("staff")
                  navigation("/staff-members")
                }}
              >
                Staff Members
              </p>
            )}

            {menu === "profile" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/my-profile")}
              >
                Profile
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("profile")
                  navigation("/my-profile")
                }}
              >
                Profile
              </p>
            )}
            {menu === "coupons" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/coupons")}
              >
                Coupons
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("coupons")
                  navigation("/coupons")
                }}
              >
                Coupons
              </p>
            )}
            {menu === "performance" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/performance")}
              >
                Performance
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("performance")
                  navigation("/performance")
                }}
              >
                Performance
              </p>
            )}

            {menu === "settings" ? (
              <p
                style={{ cursor: "pointer", color: "#57b100" }}
                onClick={() => navigation("/settings")}
              >
                Settings
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenu("settings")
                  navigation("/settings")
                }}
              >
                Settings
              </p>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default SideMenu
