import React, { useState, useEffect, createContext } from "react"

export const MenuContext = createContext()

export const MenuProvider = props => {
  //default value admin
  const [menu, setMenu] = useState("")

  // it can be home || campaign || test || contacts || staff || profile || settings

  return (
    <MenuContext.Provider value={{ menu, setMenu }}>
      {props.children}
    </MenuContext.Provider>
  )
}
