import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import "./PopUp.css"

const PopUp = props => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="custom-dialog">
        <div className="cd-inner">
          <p className="cd-heading">{props.title || "Title"}</p>
          <p className="cd-text">{props.text || "Description"}</p>
          <div className="button-set">
          {
              props.button1 ? (
                  <button className="pop-btn">
                      {props.button1 || ""}
                  </button>
              ):null
          }

          {
              props.button2 ? (
                <button className="pop-btn">
                    {props.button2 || ""}
                </button>
              ):null
          }
          <button onClick={props.okAction} className="pop-btn">{props.okText || "Ok"}</button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default PopUp
