
import React from 'react';
import "./Pagination.css"

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  console.log('currentpages are', pageNumbers.length)

  return (
   
      <div className='pagination'>
        {pageNumbers.length > 1 && pageNumbers.map(number => {
        return( 
          <div onClick={() => paginate(number)} key={number} className='page-item'>
            <p className='page-link'>
              {number}
            </p>
          </div>
           )
        })}
      </div>
   
  );
};

export default Pagination;