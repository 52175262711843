import React, { useState, useEffect } from "react"

import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"
import "../Signin/Signin.css"
import Navigation from "../../navigation/navigation"
import { useNavigate,useParams } from "react-router-dom"
import { useContext } from "react"
import { TokenContext } from "../../context/TokenContext"
import axios from "axios"
import GoogleLogin from "react-google-login"
import FacebookLogin from "react-facebook-login"

const TestLogin = () => {
  const navigation = useNavigate()

  const { id } = useParams()

  console.log('param id is',id)

  const { token, setToken, user, setUser } = useContext(TokenContext)
  const [loading, setLoading] = useState(false)

  const [details, setDetails] = useState({
    email: "",
    password: ""
  })

  useEffect(() => {
    const checkData = () => {
      let checkToken = localStorage.getItem("@storage_Key")
      console.log(`check token is ${checkToken}`)
      if (
        checkToken === null ||
        checkToken === undefined ||
        checkToken === ""
      ) {
        console.log("check")
      } else {
        navigation("/campaigns")
      }
    }

    checkData()
  }, [])

  const handleSignIn = e => {
    e.preventDefault()

    if (details.password === "" || details.email === "") {
      alert("Please fill all the required information")
      return
    }

    if (!details.email.includes("@")) {
      alert("Invalid Email format")
      return
    }

    setLoading(true)

    let JSONed = JSON.stringify(details)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/login/",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed
    }

    axios(config)
      .then(response => {
        console.log(response.data)

        localStorage.setItem("@storage_Key", response.data.access_token)
        localStorage.setItem(
          "@storage_Object",
          JSON.stringify(response.data.user)
        )
        setLoading(false)
        // alert("Login Successfull")
        navigation("/add-testimonial")
      })
      .catch(err => {
        console.log(err.response.data)

        if (
          err?.response?.data?.non_field_errors !== undefined ||
          err?.response?.data?.non_field_errors.length > 0
        ) {
          setLoading(false)
          alert(err?.response?.data?.non_field_errors[0])
        } else {
          setLoading(false)
          alert(
            "Login Failed, Please check your internet connection and try again later"
          )
        }
      })
  }

  const responseGoogle = response => {
    console.log(response)

    //response.accessToken

    let accessObj = {
      access_token: response.accessToken
    }

    let JSONed = JSON.stringify(accessObj)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/social-auth/google/login/",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed
    }
    axios(config)
      .then(response => {
        console.log(response.data)

        localStorage.setItem("@storage_Key", response.data.access_token)
        localStorage.setItem(
          "@storage_Object",
          JSON.stringify(response.data.user)
        )
        localStorage.setItem("@google_Name", response.data.user.username)

        setLoading(false)
        alert("Login Successfull")
        navigation("/add-testimonial")
      })
      .catch(err => {
        console.log(err.response.data)

        if (
          err?.response?.data?.non_field_errors !== undefined ||
          err?.response?.data?.non_field_errors.length > 0
        ) {
          setLoading(false)
          alert(err?.response?.data?.non_field_errors[0])
        } else {
          setLoading(false)
          alert(
            "Login Failed, Please check your internet connection and try again later"
          )
        }
      })
  }

  const responseFacebook = response => {
    //response.accessToken

    let accessObj = {
      access_token: response.accessToken
    }

    let JSONed = JSON.stringify(accessObj)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/social-auth/facebook/login/",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed
    }
    axios(config)
      .then(response => {
        console.log(response.data)

        localStorage.setItem("@storage_Key", response.data.access_token)
        localStorage.setItem(
          "@storage_Object",
          JSON.stringify(response.data.user)
        )
        setLoading(false)
        alert("Login Successfull")
        navigation("/add-testimonial")
      })
      .catch(err => {
        console.log(err.response.data)

        if (
          err?.response?.data?.non_field_errors !== undefined ||
          err?.response?.data?.non_field_errors.length > 0
        ) {
          setLoading(false)
          alert(err?.response?.data?.non_field_errors[0])
        } else {
          setLoading(false)
          alert(
            "Login Failed, Please check your internet connection and try again later"
          )
        }
      })
  }

  const handleLogin = async googleData => {
    const res = await fetch("/api/v1/auth/google", {
      method: "POST",
      body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    // store returned user somehow
  }

  return (
    <div
      className="main-landing"
      // style={{ backgroundImage: { rectangle }, backgroundColor: "grey" }}
    >
      <img src={rectangle} className="abs-rect" />

      <div
        className="landing-middle-left"
        // style={{ backgroundColor: "grey" }}
      >
        <img src={logo} className="sign-logo" />

        <div className="sign-middle">
          <div className="sign-middle-left">
            <p
              style={{
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: "bold",
                fontSize: 24
                // paddingTop: 10
              }}
            >
              Testimonial Sign In
            </p>
            <div>
              <input
                type="text"
                placeholder="Email Address"
                className="input-sign"
                onChange={e =>
                  setDetails({ ...details, email: e.target.value })
                }
              />
              <input
                type="password"
                placeholder="Password"
                className="input-sign"
                onChange={e =>
                  setDetails({ ...details, password: e.target.value })
                }
              />
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <Form.Check type="checkbox" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around"
                }}
              >
                <p style={{ marginLeft: "7px", color: "white" }}>Remember me</p>
              </div>
            </div> */}
            {/* <p
              style={{
                color: "#57b100",
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
                margin: 0,
                marginBottom: 8
              }}
              onClick={() => {
                navigation("/password-reset")
              }}
            >
              Forgot Password?
            </p> */}
            {loading ? (
              <button className="login-btn" disabled={true}>
                Loading ...
              </button>
            ) : (
              <button
                className="login-btn"
                style={{ cursor: "pointer" }}
                onClick={handleSignIn}
              >
                Log in
              </button>
            )}
            <p
              style={{
                textAlign: "center",
                color: "white",
                margin: 0,
                marginTop: 5,
                marginBottom: 5
              }}
            >
              OR
            </p>
            {/* <div className="white-btn">
              <img src={Flogo} />
              <p style={{ marginLeft: "10px" }}>Login with facebook</p>
            </div> */}
            <FacebookLogin
              appId="3142503485969853"
              callback={responseFacebook}
              render={renderProps => (
                <div
                  className="white-btn"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img src={Flogo} />
                  <p style={{ marginLeft: "10px" }}>Login </p>
                </div>
              )}
            />
            <div className="white-btn">
              <img src={Flogo} />
              <p style={{ marginLeft: "10px" }}>Login </p>
            </div>
            {/* <div className="white-btn">
              <img src={Glogo} />
              <p style={{ marginLeft: "10px" }}>Login with google</p>
            </div> */}
            <GoogleLogin
              clientId="394226388858-2rfgbomodq4qh9n9gfqp10uugb2e6s59.apps.googleusercontent.com"
              buttonText="Sign in with google"
              render={renderProps => (
                <div
                  className="white-btn"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img src={Glogo} />
                  <p style={{ marginLeft: "10px" }}>Login with Google</p>
                </div>
              )}
              onSuccess={responseGoogle}
              // onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
            ,
            {/* <GoogleLogin
              clientId=""
              buttonText="Log in with Google"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              cookiePolicy={"single_host_origin"}
              style={{
                textAlign: "center"
              }}
            /> */}
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around"
              }}
            >
              <p
                style={{ marginLeft: "7px", color: "white", fontSize: "14px" }}
              >
                Dont have an account ?
              </p>
              <p
                style={{
                  marginLeft: "7px",
                  color: "#57b100",
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: "14px"
                }}
                onClick={() => navigation("/sign-up")}
              >
                Sign up here
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <img src={laptop} className="landing-middle-right" />
    </div>
  )
}

export default TestLogin
