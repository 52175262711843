import React, { useState, useEffect, useContext } from "react"
import { Offcanvas } from "react-bootstrap"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import logo from "../../assets/images/logo.png"
import { TokenContext } from "../../context/TokenContext"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Fade } from "react-reveal"
import "./Coupons.css"
import Cimg from "./../../assets/images/c1.png"
import moment from "moment"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import UploadIcon from "./../../assets/images/upload.png"
import CrossIcon from "./../../assets/images/cross.png"
import Switch from "@material-ui/core/Switch"
import SearchImg from "./../../assets/images/search.png"
import Pagination from "../Components/Pagination"
import { CircularProgress } from "@material-ui/core"

const Coupons = () => {
  const { user, setUser, token, setToken } = useContext(TokenContext)
  const [coupons, setCoupons] = useState([])
  const [currentCoupon, setCurrentCoupon] = useState({
    key: ""
  })
  const [openShareModal, setOpenShareModal] = useState(false)
  const [checked, setChecked] = useState(false)
  const [contacts, setContacts] = useState([])
  const [showContacts, setShowContacts] = useState(false)
  const [filterText, setFilterTxt] = useState("")
  const [shareArray, setShareArray] = useState([])
  const [selectedCoupon, setSelectedCoupon] = useState({})
  const [contentLoading, setContentLoading] = useState(true)

  console.log(shareArray)

  console.log('the current coupon is',currentCoupon)

  const shareCoupons = () => {
    if (shareArray.length === 0) {
      alert("Please select atleast one contact to share with")
      return
    }
    let looper = [...shareArray]

    looper.forEach((loop, index) => {
      // let loopFormat = {
      //   email: loop.contactEmail,
      //   CouponName: currentCoupon.couponName,
      //   Active: currentCoupon.active,
      //   DateStart: currentCoupon.startDate,
      //   EndDate: currentCoupon.endDate,
      //   description: currentCoupon?.description,
      //   business:currentCoupon?.business || 'Business'
      // }
      let loopFormat = {
        email: loop.contactEmail,
        Active: currentCoupon?.active,
        firstname: loop.contactName,
        description: currentCoupon?.description,
        business:currentCoupon?.business || 'Business'
      }

      console.log(loopFormat)

      let JSONloop = JSON.stringify(loopFormat)
      var config = {
        method: "POST",
        url: "https://ready-shout-30574.botics.co/users/api/sendMessage/",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
        },
        data: JSONloop
      }

      axios(config)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })

      setShareArray([])
    })
    alert("Emails have been sent")
  }

  const toggleChecked = () => {
    if (!checked) {
      setShareArray(contacts)
    } else {
      setShareArray([])
    }
    setChecked(!checked)
  }

  const navigation = useNavigate()

  console.log('the user is', user, 'the token is',token)

  useEffect(() => {

    if(token === null || token === "" || token === undefined) {
      return
    }

    setContentLoading(true)
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/coupons/api/Coupon/?limit=10000",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    axios(config).then(response => {

      let filteredTests = response.data.results.filter(result => {
        return (
          result.usedID ===
          user.id
        )
      })
      console.log('filters are', filteredTests)
      setCoupons(filteredTests)
      setContentLoading(false)
    })
    .catch((err)=>{
      alert(err)
      setContentLoading(false)
    })
  }, [token])

  useEffect(() => {

 

    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/contact/api/Contact/?limit=1000000000",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    axios(config).then(response => {
      

      let filteredTests = response.data.results.filter(result => {
        return (
          result.email ===
          user.email
        )
      })

      let tempData = filteredTests
      tempData.forEach((data, index) => {
        data.isChecked = false
      })

      tempData.forEach((data, index) => {
        data.id = index
      })

      setContacts(tempData)
    })
  }, [])

  const patchCoupon = coup => {
    let patchOne = {
      active: false
    }
    let patchedObj = JSON.stringify(patchOne)

    var config = {
      method: "PATCH",
      url: `https://ready-shout-30574.botics.co/coupons/api/Coupon/${coup.key}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: patchedObj
    }

    axios(config)
      .then(response => {
        console.log(response.data)
        window.location.reload()
      })
      .catch(err => {
        alert("An error occured")
      })
  }
  const patchCouponActive = coup => {
    let patchOne = {
      active: true
    }
    let patchedObj = JSON.stringify(patchOne)

    var config = {
      method: "PATCH",
      url: `https://ready-shout-30574.botics.co/coupons/api/Coupon/${coup.key}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: patchedObj
    }

    axios(config)
      .then(response => {
        console.log(response.data)
        window.location.reload()
      })
      .catch(err => {
        alert("An error occured")
      })
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

   // Get current posts
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = coupons.slice(indexOfFirstPost, indexOfLastPost);
 
   // Change page
   const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%"
            }}
          >
            <p
              style={{
                width: "50%",
                marginTop: "40px",
                color: "white",
                fontSize: "26px",
                fontWeight: "bold",
                fontFamily: "Work Sans"
              }}
            >
              {`< Coupons`}
            </p>
            <div className="coup-btn" onClick={() => navigation("/add-coupon")}>
              <p>Add a coupon</p>
            </div>
          </div>
        </div>
        <table className="coupon-table">
          <tr>
            <th></th>
            <th>Coupon Name</th>
            <th>Active/Inactive</th>
            <th>Date Started</th>
            <th>Date ends</th>
            <th>Activate/Deactivate</th>
            <th>Share Coupon</th>
          </tr>
          {
          
          
          currentPosts.map((coupon, index) => {
            return (
              <tr
                style={{
                  background: "#2D2C2C",
                  height: "80px",
                  borderBottom: "1px solid gray"
                }}
              >
                <td>
                  {coupon.pic === "" ? (
                    <img src={Cimg} style={{ marginLeft: "20px" }} />
                  ) : (
                    <img
                      src={coupon?.pic?.split("?")[0]}
                      style={{
                        marginLeft: "20px",
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%"
                      }}
                    />
                  )}
                </td>

                <td>{coupon.couponName}</td>
                <td style={{ color: coupon.active ? "#57B100" : "#FC5C29" }}>
                  {coupon.active ? "Active" : "Inactive"}
                </td>
                <td>{moment(coupon.startDate).format("D/MM/YYYY")}</td>
                <td>{moment(coupon.endDate).format("D/MM/YYYY")}</td>
                <td>
                  {coupon.active ? (
                    <div
                      className="de-actived"
                      onClick={() => {
                        patchCoupon(coupon)
                      }}
                    >
                      <p>Deactivate</p>
                    </div>
                  ) : (
                    <div
                      className="actived"
                      onClick={() => {
                        patchCouponActive(coupon)
                      }}
                    >
                      <p>Activate</p>
                    </div>
                  )}
                </td>
                <td>
                  <div
                    className="shared-btn"
                    onClick={() => {
                      setOpenShareModal(true)
                      setCurrentCoupon(coupon)
                    }}
                  >
                    <p>Share</p>
                  </div>
                </td>
              </tr>
            )
          })}

          {
            coupons.length <= 0 && contentLoading ? (
              <CircularProgress color="white" style={{color:'white',alignSelf:'center',margin:'20px'}}/>
            ):
            coupons.length <= 0 ?
            <p style={{color:'white',alignSelf:'center',margin:'20px'}}>No Coupons Created</p>:null
          }
        </table>

        <Pagination
                postsPerPage={postsPerPage}
                totalPosts={coupons.length}
                paginate={paginate}
              />
      </div>

      <Dialog open={openShareModal} onClose={() => setOpenShareModal(false)}>
        <div
          className="share-coupon-modal"
          style={{ height: showContacts ? "700px" : "500px" }}
        >
          <img
            src={CrossIcon}
            className="share-abs"
            onClick={() => setOpenShareModal(false)}
          />
          <div className="share-holder">
            <div className="share-modal-top">
              <p style={{ color: "white", fontSize: "28px", fontWeight: 600 }}>
                Share Coupon
              </p>
              <div className="share-modal-top-right">
                <p
                  style={{
                    fontSize: "12px",
                    color: "white"
                  }}
                >
                  Send to all contacts
                </p>
                <Switch checked={checked} onChange={toggleChecked} />
              </div>
            </div>

            <div className="share-modal-middle">
              <img src={SearchImg} />
              <input
                className="share-modal-middle-input"
                type="text"
                placeholder="Search for contacts"
                onChange={e => setFilterTxt(e.target.value)}
                onFocus={() => setShowContacts(true)}
                //   onBlur={() => setShowContacts(false)}
              />
            </div>
            {true ? (
              <div
                style={{
                  position: "absolute",
                  background: "#484848",
                  height: "150px",
                  overflowY: "scroll",
                  width: "450px",
                  top: "225px"
                }}
              >
                {contacts
                  .filter(contact =>
                    contact.contactName
                      .toLowerCase()
                      .includes(filterText.toLowerCase())
                  )
                  .map((contact, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center"
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ marginLeft: "20px" }}
                          checked={contact.isChecked}
                          onChange={() => {
                            console.log("hi")
                            if (contact.isChecked === true) {
                              console.log("bi")
                              let temp_state = [...contacts]
                              let temp_element = { ...temp_state[contact.id] }
                              temp_element.isChecked = false
                              temp_state[contact.id] = temp_element

                              // setShareArray([...shareArray, temp_element])

                              setContacts(temp_state)

                              let temp_state_2 = [...shareArray]

                              // temp_state_2.splice(contact.id, 1)
                              const finalArray = temp_state_2.filter(item => item.id !== contact.id)

                              setShareArray(finalArray)

                              return
                            } else if (contact.isChecked === false) {
                              console.log("huh")
                              let temp_state = [...contacts]
                              let temp_element = { ...temp_state[contact.id] }
                              temp_element.isChecked = true
                              temp_state[contact.id] = temp_element
                              console.log(temp_state)

                              setContacts(temp_state)
                              setShareArray([...shareArray, temp_element])

                              // setContacts({
                              //   ...contacts[index],
                              //   isChecked: true
                              // })
                            }
                          }}
                        />
                        <p style={{ marginLeft: "10px", color: "white" }}>
                          {contact.contactName}
                        </p>
                      </div>
                    )
                  })}
              </div>
            ) : null}
          </div>

          <div className="send-btn" onClick={shareCoupons}>
            <p style={{ color: "white" }}>Send</p>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Coupons
