import React, { useState, useEffect, useContext } from "react"
import "./StaffMembers.css"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import DownArrow from "../../assets/images/downarrow.png"
import RightArrow from "../../assets/images/rightarrows.png"
import ProfileImg from "../../assets/images/usernew.png"

import EditImg from "../../assets/images/edit.png"
import BinImg from "../../assets/images/bin.png"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import { TokenContext } from "../../context/TokenContext"
import Pagination from "../Components/Pagination"
import { CircularProgress } from "@material-ui/core"

const StaffMembers = () => {
  const navigation = useNavigate()
  const [staffMembers, setStaffMembers] = useState([])
  const [newStaffMembers, setNewStaffMembers] = useState([])
  const [arrayOfBooleans, setArrayOfBooleans] = useState([])
  const [currentUser, setCurrentUser] = useState({})
  const [currentEditUser, setCurrentEditUser] = useState({})
  const [apphendUser, setApphendUser] = useState({})
  const [open, setOpen] = useState(false)
  const { token, user, profileData } = useContext(TokenContext)
  const [contentLoading, setContentLoading] = useState(false)

  const roles = ["user", "admin"]

  console.log(`the idea is`, profileData)


  const getStaffDetails = async id => {
    var superConfig = {
      method: "GET",
      url: `https://ready-shout-30574.botics.co/allUsers/api/allUsers/${id}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    try {
      let response = await axios(superConfig)
      return response
    } catch (err) {
      let response = err
      setContentLoading(false)
      return response
    }
  }

  useEffect(async() => {

    setContentLoading(true)
    
    const fetcher = async () => {
     
      let modifiedArray = []

      for (const each of profileData?.staffMembers) {
        let newresponse = await getStaffDetails(each.staffID.id)

        modifiedArray.push({
          email: newresponse.data.email,
          id: newresponse.data.id,
          role: newresponse.data.role,
          username: newresponse.data.username,
          FName: newresponse.data.FName,
          LName: newresponse.data.lName,
          CompanyName: newresponse.data.CompanyName,
          phone: newresponse.data.phone,
          country: newresponse.data.country,
          city: newresponse.data.city,
          state: newresponse.data.state,
          zip: newresponse.data.zip,
          PrimaryAddress: newresponse.data.PrimaryAddress,
          SecondaryAddress: newresponse.data.SecondaryAddress
        })
      }
      return modifiedArray
    }

    let resp = await fetcher()
    

    let mirrorFilter = resp.filter(finalResult => {
      return finalResult.email !== user.email
    })

    console.log(mirrorFilter)

    let tempData = mirrorFilter

    tempData.forEach(element => {
      element.isOpen = false
    })

    tempData.forEach((element, id) => {
      element.index = id
    })

    tempData.forEach(element => {
      element.isEdit = false
    })

    setStaffMembers(tempData)
    setContentLoading(false)
   
  }, [token])

  console.log('staff mem are',staffMembers)

  // useEffect(() => {
  //   var config = {
  //     method: "GET",
  //     url: "https://ready-shout-30574.botics.co/allUsers/api/allUsers/?limit=100000",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`
  //     }
  //   }

  //   axios(config)
  //     .then(resp => {
  //       console.log(resp.data.results)
  //       setStaffMembers(resp.data.results)

  //       let filteredTests = resp.data.results.filter(result => {
  //         return result.CompanyName === profileData.CompanyName
  //       })

  //       let mirrorFilter = filteredTests.filter(finalResult => {
  //         return finalResult.email !== user.email
  //       })

  //       console.log(mirrorFilter)

  //       let tempData = mirrorFilter

  //       tempData.forEach(element => {
  //         element.isOpen = false
  //       })

  //       tempData.forEach((element, id) => {
  //         element.index = id
  //       })

  //       tempData.forEach(element => {
  //         element.isEdit = false
  //       })

  //       setStaffMembers(tempData)
  //       // const arr = Array(resp.data.results.length)
  //       // arr.fill(false)
  //       // setArrayOfBooleans(arr)
  //     })
  //     .catch(err => {
  //       console.log(err.message)
  //       alert("An error occured")
  //     })
  // }, [])

  const dropDownSwitch = index => {
    //filter the selected object to update its selected attribute

    const elementsIndex = staffMembers.findIndex(
      element => element.index === index
    )

    let newArray = [...staffMembers]
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isOpen: !newArray[elementsIndex].isOpen
    }

    if (newArray[elementsIndex].isEdit) {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        isEdit: false
      }
      let updatedArray = newArray
      setStaffMembers(updatedArray)
    } else {
      let updatedArray = newArray
      setStaffMembers(updatedArray)
    }
  }

  const dropDownEditSwitch = index => {
    const elementsIndex = staffMembers.findIndex(
      element => element.index === index
    )

    let newArray = [...staffMembers]
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isEdit: !newArray[elementsIndex].isEdit
    }

    if (newArray[elementsIndex].isOpen) {
      let updatedArray = newArray
      setStaffMembers(updatedArray)
    } else {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        isOpen: true
      }
      let updatedArray = newArray
      setStaffMembers(updatedArray)
    }
  }

  // const selectChange = index => {
  //   //filter the selected object to update its selected attribute

  //   const elementsIndex = members.findIndex(element => element.index == index)
  //   console.log(elementsIndex)
  //   let newArray = [...members]

  //   newArray[elementsIndex] = { ...newArray[elementsIndex], isOpen: true }

  //   let updatedArray = newArray

  //   setMembers(updatedArray)
  // }

  // console.log(staffMembers)

  // const [members, setMembers] = useState([
  //   {
  //     name: "Sarah Johnson",
  //     email: "sarahjohnson@gmail.com",
  //     number: "+123456789",
  //     accessType: "Manager",
  //     isOpen: true,
  //     index: 0
  //   },
  //   {
  //     name: "John Doe",
  //     email: "johndoe@gmail.com",
  //     number: "+156456789",
  //     accessType: "Manager",
  //     isOpen: false,
  //     index: 1
  //   },
  //   {
  //     name: "John Smith",
  //     email: "johnsmith@gmail.com",
  //     number: "+15645456789",
  //     accessType: "Manager",
  //     isOpen: false,
  //     index: 2
  //   }
  // ])

  const handleDeletion = () => {
    var config = {
      method: "DELETE",
      url: `https://ready-shout-30574.botics.co/allUsers/api/allUsers/${currentUser.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        alert("Member Deleted Successfully")

        let uiUpdate = [...staffMembers]

        const elementsIndex = uiUpdate.findIndex(
          element => element.id === currentUser.id
        )

        uiUpdate.splice(elementsIndex, 1)

        setStaffMembers(uiUpdate)
        setOpen(false)
      })
      .catch(err => {
        console.log(err)
        alert("Failed to Delete staff member")
        setOpen(false)
      })
  }

  const updateStaff = () => {

    let deployObj = {
      email: currentEditUser.email,
      id:currentEditUser.id,
      role:currentEditUser.role,
      phone:currentEditUser.phone,
      username:currentEditUser.username
    }

    let JSONed = JSON.stringify(deployObj)


    console.log(JSONed)
    var config = {
      method: "PATCH",
      url: `https://ready-shout-30574.botics.co/allUsers/api/allUsers/${currentEditUser.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        alert("Member updated Successfully, refresh to see changes")
      })
      .catch(err => {
        console.log(err)
        alert("Failed to Update staff member")
      })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = staffMembers.slice(indexOfFirstPost, indexOfLastPost)

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%"
            }}
          >
            <p
              style={{
                width: "90%",
                marginTop: "40px",
                color: "white",
                fontSize: "26px",
                fontWeight: "bold",
                fontFamily: "Work Sans",
                textAlign: "left"
              }}
            >
              {`Staff Members`}
            </p>

            <div
              className="staff-div"
              style={{
                cursor: "pointer",
                // opacity: globalRole === "user" ? 0 : 1
                opacity: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
              onClick={() => navigation("/add-staff")}
            >
              <p style={{ textAlign: "center" }}>Add new staff member</p>
            </div>
            <div
              className="staff-div"
              style={{
                cursor: "pointer",
                // opacity: globalRole === "user" ? 0 : 1,
                opacity: 1,
                marginLeft: "20px"
              }}
              onClick={() => navigation("/performance")}
            >
              <p>Team Performance</p>
            </div>
          </div>

          {/*globalRole !== "user" */}

          {/* <p style={{ textAlign: "left", color: "white" }}>{`${
            profileData.CompanyName || "Company"
          }'s Staffs`}</p> */}

          {true ? (
            <div className="all-staff">
              {
                staffMembers.length <= 0 && contentLoading ? (
                  <CircularProgress color="white" style={{color:'white',alignSelf:'center',margin:'20px'}}/>
                ):
                staffMembers.length <=0 ? (
                  <p style={{ color: "white",alignSelf:'center',margin:'20px' }}>No Staff Members Created</p>
                ):
               currentPosts.map((members, i) => {
                return (
                  <div className="all-staff-each">
                    <div className="all-staff-each-top">
                      <div className="all-staff-each-top-left">
                        <div className="all-staff-each-top-left-more">
                          {members.isOpen ? (
                            <img
                              src={DownArrow}
                              onClick={dropDownSwitch.bind(this, i)}
                            />
                          ) : (
                            <img
                              src={RightArrow}
                              onClick={dropDownSwitch.bind(this, i)}
                            />
                          )}

                          <img
                            src={ProfileImg}
                            style={{ height: "45px", width: "45px" }}
                          />
                        </div>
                        {members.isEdit ? (
                          <input
                            type="text"
                            placeholder={members?.username}
                            className="input-sign-new"
                            onChange={e =>
                              setCurrentEditUser({
                                ...currentEditUser,
                                username: e.target.value
                              })
                            }
                            style={{
                              height: "30px",
                              marginLeft: "20px",
                              marginBottom: "0px",
                              width: "250px"
                            }}
                          />
                        ) : (
                          <p
                            style={{
                              textAlign: "left",
                              width: "50%",
                              color: "white"
                            }}
                          >
                            {members.username}
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "50px"
                        }}
                      >
                        <img
                          src={EditImg}
                          onClick={() => {
                            setCurrentEditUser(members)
                            dropDownEditSwitch(i)
                          }}
                        />
                        <img
                          src={BinImg}
                          onClick={() => {
                            setCurrentUser(members)
                            setOpen(true)
                          }}
                        />
                      </div>
                    </div>
                    {members.isOpen ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          width: "40%",
                          alignSelf: "flex-start"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            width: "100%",
                            color: "white",
                            marginTop: "20px",
                            alignItems: "center"
                          }}
                        >
                          <p style={{ fontWeight: 700 }}>Email Address</p>
                          {members.isEdit ? (
                            <input
                              type="text"
                              placeholder={members?.email}
                              className="input-sign-new"
                              style={{
                                height: "30px",
                                marginLeft: "20px",
                                marginBottom: "0px"
                              }}
                              onChange={e =>
                                setCurrentEditUser({
                                  ...currentEditUser,
                                  email: e.target.value
                                })
                              }
                            />
                          ) : (
                            <p
                              style={{
                                fontSize: "16px",
                                color: "lightgray",
                                marginLeft: "50px"
                              }}
                            >
                              {members?.email || "-"}
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            width: "100%",
                            color: "white",
                            marginTop: "20px",
                            alignItems: "center"
                          }}
                        >
                          <p style={{ fontWeight: 700 }}>Phone Number</p>

                          {members.isEdit ? (
                            <input
                              type="text"
                              placeholder={members?.phone}
                              className="input-sign-new"
                              style={{
                                height: "30px",
                                marginLeft: "20px",
                                marginBottom: "0px"
                              }}
                              onChange={e =>
                                setCurrentEditUser({
                                  ...currentEditUser,
                                  phone: e.target.value
                                })
                              }
                            />
                          ) : (
                            <p
                              style={{
                                fontSize: "16px",
                                color: "lightgray",
                                marginLeft: "50px"
                              }}
                            >
                              {members?.phone || "-"}
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            width: "100%",
                            color: "white",
                            marginTop: "20px",
                            alignItems: "center"
                          }}
                        >
                          <p style={{ fontWeight: 700 }}>Access Type</p>

                          {members.isEdit ? (
                            <select
                              id="campaigns"
                              name="Campaigns"
                              // onChange={e => setDetails({ ...details, role: e.target.value })}
                              onChange={e =>
                                setCurrentEditUser({
                                  ...currentEditUser,
                                  role: e.target.value
                                })
                              }
                              style={{
                                height: "30px",
                                marginLeft: "20px",
                                marginBottom: "0px"
                              }}
                              className="input-sign-new"
                            >
                              <option value="">Staff Member Access type</option>
                              {roles.map((role, id) => (
                                <option key={id} value={role}>
                                  {role}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <p
                              style={{
                                fontSize: "16px",
                                color: "lightgray",
                                marginLeft: "50px"
                              }}
                            >
                              {members?.role || "-"}
                            </p>
                          )}
                        </div>
                        {members.isEdit ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              width: "50%",
                              color: "white",
                              marginTop: "20px",
                              alignItems: "center",
                              background: "#57b100",
                              borderRadius: "20px",
                              height: "40px",
                              alignSelf: "flex-start",
                              cursor: "pointer"
                            }}
                            onClick={updateStaff}
                          >
                            <p>Change</p>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>
          ) : (
            <div>
              <p style={{ color: "white", textAlign: "left" }}>
                You do not have the right privilege to access staff members,
                only an admin or moderator can access
              </p>
            </div>
          )}
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={staffMembers.length}
            paginate={paginate}
          />
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete user"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete ${currentUser.username}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={handleDeletion} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default StaffMembers
