import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import "./Landing.css"

const Landing = () => {
  let navigation = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigation("/sign-in")
    }, 3000)
  }, [])

  return (
    <div className="main-landing">
      <img src={rectangle} className="abs-rect" />

      <div className="landing-middle-left">
        <img src={logo} className="landing-logo" />

        <p className="landing-txt">
          “ReadyShout is a review platform created to make it easy for small
          businesses to request, upload, and edit positive video testimonials
          from happy customers and counteract negative online reviews.”
        </p>
      </div>

      <img src={laptop} className="landing-middle-right" />
    </div>
  )
}

export default Landing
