import React, { useState, useEffect, useContext, useRef } from "react"
import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"
import "./../AddStaff/AddStaff.css"
import "./AddContact.css"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"
import { TokenContext } from "./../../context/TokenContext"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import UploadIcon from "./../../assets/images/upload.png"
import CamImg from "./../../assets/images/cam.png"

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const AddContact = props => {
  const navigation = useNavigate()
  const [state, setState] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [campaigns, setCampaigns] = useState([])
  const [loading, setLoading] = useState(false)
  const { token, setToken, user, setUser } = useContext(TokenContext)
  let imgRef = useRef(null)

  const roles = ["Manager", "User", "Admin"]

  const [details, setDetails] = useState({
    contactName: "",
    contactEmail: "",
    contactNumber: "",
    usedID: user.id,
    email: user.email
  })

  const [currentImg, setCurrentImg] = useState(null)
  const [uploaderObj, setUploaderObj] = useState(null)

  console.log(details)

  const imageUpload = e => {
    const [file] = e.target.files
    setCurrentImg(URL.createObjectURL(file))
    let obj = {
      type: file.type,
      name: file.name
    }
    setUploaderObj(e.target.files[0])
  }

  const addContact = () => {
    /*Upload api for image */

    if (uploaderObj === null) {
      if (
        details.contactEmail === "" ||
        details.contactName === "" ||
        details.contactNumber === ""
      ) {
        alert("Please fill all the fields")
        return
      }

      // if(!details.contactName.includes("+")) {
      //   alert("PLease enter a valid format for the phone number (Ex: +92 XXXXXXXX")
      //   return
      // }

      let dataObjJSON = JSON.stringify(details)

      console.log('data data',dataObjJSON)

      let config = {
        method: "POST",
        url: "https://ready-shout-30574.botics.co/contact/api/Contact/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
        },
        data: dataObjJSON
      }

      axios(config)
        .then(response => {
          console.log(response.data)
          alert("Contact Added Successfully")
        })
        .catch(err => {
          alert("An Error Occured")
        })
    } else {
      let newForm = new FormData()

      newForm.append("document", uploaderObj)

      let configs = {
        method: "POST",
        url: `https://ready-shout-30574.botics.co/uploader/api/uploader/`,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
        },
        data: newForm
      }

      axios(configs)
        .then(response => {
          let newDetails = {
            contactName: details.contactName,
            contactEmail: details.contactEmail,
            contactNumber: details.contactNumber,
            usedID: user.id,
            contactPhoto: response.data.document,
            email: user.email
          }

          console.log("new details are", newDetails)

          let configs2 = {
            method: "POST",
            url: "https://ready-shout-30574.botics.co/contact/api/Contact/",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
            },
            data: JSON.stringify(newDetails)
          }

          axios(configs2)
            .then(response => {
              console.log(response.data)
              alert("Contact Added Successfully")
            })
            .catch(err => {
              console.log(err)
              alert("An Error Occured")
            })
        })
        .catch(err => {
          console.log(err)
          if (err.response.data.document.length > 0) {
            alert(err.response.data.document[0])
          } else {
            alert(err)
          }
        })
    }
  }

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <p
            style={{
              width: "90%",
              marginTop: "40px",
              color: "white",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Work Sans",
              cursor: "pointer"
            }}
            onClick={() => navigation("/contacts")}
          >
            {"< Add Contact"}
          </p>
          <div className="settings-main-short">
            <p style={{ color: "white", fontSize: "18px", textAlign: "left" }}>
              Enter Contact Details
            </p>

            <input
              type="file"
              ref={imgRef}
              style={{ display: "none" }}
              accept="image/png, image/gif, image/jpeg"
              onChange={imageUpload}
            />
            <div className="contact-img" onClick={() => imgRef.current.click()}>
              <img
                src={currentImg === null ? CamImg : currentImg}
                style={{
                  height: currentImg === null ? "60%" : "100%",
                  width: currentImg === null ? "60%" : "100%",
                  borderRadius: "50px"
                }}
              />
            </div>

            {/* <input
              type="text"
              placeholder="Select a Campaign"
              className="input-sign-new"
              style={{ marginTop: "40px" }}
            /> */}
            <input
              type="text"
              placeholder="Contact Name"
              className="input-sign-new"
              onChange={e =>
                setDetails({ ...details, contactName: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Contact Email Address"
              className="input-sign-new"
              onChange={e =>
                setDetails({ ...details, contactEmail: e.target.value })
              }
            />
            {/* <input
              placeholder="Contact Phone Number (Ex: +92 1234532155)"
              className="input-sign-new"
              onChange={e =>
                setDetails({ ...details, contactNumber: e.target.value })
              }
            /> */}
            {/* <PhoneInput
              placeholder="Enter phone number"
              // value={value}
              onChange={e =>
                setDetails({ ...details, contactNumber: e })}
            /> */}
         
            <PhoneInput
              country={"us"}
              containerStyle={{
                alignSelf:'center',
                width:'62%'
              }}
              inputStyle={{
                width:'100%',
                alignSelf:'center',
                height:'40px'
              }}
              placeholder={'Number'}
              value={details.contactNumber}
              onChange={phone => setDetails((prev)=>({...prev,contactNumber:phone}))}
            />
       

            {loading ? (
              <CircularProgress color="green"/>
            ) : (
              <button className="login-btn-small" onClick={addContact}>
                {`Add Contact`}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddContact
