import React, { useState, useEffect, createContext } from "react"

export const NotificationContext = createContext()

export const NotificationProvider = props => {
  //default value admin
  const [fromHeader, setFromHeader] = useState(false)

  // it can be home || campaign || test || contacts || staff || profile || settings

  return (
    <NotificationContext.Provider value={{ fromHeader, setFromHeader }}>
      {props.children}
    </NotificationContext.Provider>
  )
}
