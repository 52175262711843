import React, { useState, useEffect, useContext } from "react"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import RightArrow from "../../assets/images/rightarrow.png"
import "./Settings.css"
import NotPeople from "../../assets/images/notificationimg.png"
import { Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { TokenContext } from "../../context/TokenContext"
import axios from "axios"
import { NotificationContext } from "../../context/NotificationContext"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import { Switch } from "@material-ui/core"
import ProfileImg from "../../assets/images/usernew.png"

const Settings = () => {
  const [currentMode, setCurrentMode] = useState("main")
  // main || notifications || terms || privacy || password

  const { token, setToken, user, setUser } = useContext(TokenContext)
  const { fromHeader, setFromHeader } = useContext(NotificationContext)

  const navigation = useNavigate()

  

  const [privacyPolicy, setPrivacyPolicy] = useState([])
  const [termsCondition, setTermsCondition] = useState([])
  const [notificationList, setNotificationList] = useState([])
  const [currentBox, setCurrentBox] = useState({})
  const [open, setOpen] = useState(false)
  const [lengths, setLengths] = useState(0)
  const [requests, setRequests] = useState([])
  const [reTrigger, setReTrigger] = useState(false)
  const [turnNoti, setTurnNoti] = useState(true)

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      navigation("/sign-in")
    }
  }, [])

  useEffect(() => {
    if (fromHeader === true) {
      setCurrentMode("notifications")

      setTimeout(() => {
        setFromHeader(false)
      }, 50000)
    } else {
      setCurrentMode("main")
    }
  }, [fromHeader])

  useEffect(() => {
    let filterLength = notificationList.filter(nl => nl.inChecked === false)
    setLengths(filterLength.length)
  }, [notificationList])

  useEffect(() => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/modules/privacy-policy/",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        setPrivacyPolicy(resp.data.results)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/modules/terms-and-conditions/",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        setTermsCondition(resp.data.results)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    getNotifications()
  }, [reTrigger])

  console.log(requests)

  const getNotifications = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/notifications/api/notifications/?limit=100000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)
        
        let idFilter = resp.data.results.filter((each)=>{
          return(
            each.userID === user.id
          )
        })
        setNotificationList(idFilter)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }

  const logOut = async () => {
    try {
      localStorage.removeItem("@storage_Key")
      localStorage.removeItem("@storage_Object")
      localStorage.removeItem("@google_Name")
      setToken("")
      setUser({})
      navigation("/sign-in")
    } catch (e) {
      console.log(e)
      alert("An Error Occured")
    }
  }

  const markedForRead = a => {
    console.log(`${localStorage.getItem("@storage_Key")}`)
    // setCurrentBox({ ...currentBox, inChecked: true })
    setOpen(false)
    // let data = { ...currentBox }
    // data.inChecked = !currentBox.inChecked

    // selectedObj.inChecked = !a.inChecked

    let transportObj = {
      inChecked: true
    }
    const dataBody = JSON.stringify(transportObj)

    var config = {
      method: "PATCH",
      url: `https://ready-shout-30574.botics.co/notifications/api/notifications/${currentBox.key}/`,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: dataBody
    }

    axios(config)
      .then(response => {
        console.log(response.data)
        alert("Notification Marked as Read")
        window.location.reload()
      })
      .catch(err => {
        console.log(err)
        alert("An Error Occured")
      })
  }

  console.log(reTrigger)

  const multipleRead = () => {
    if (requests.length <= 0) {
      return
    }
    setReTrigger(!reTrigger)

    requests.forEach(req => {
      let tObj = {
        inChecked: true
      }

      let JSONed = JSON.stringify(tObj)

      let config = {
        method: "PATCH",
        url: `https://ready-shout-30574.botics.co/notifications/api/notifications/${req.key}/`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
        },
        data: JSONed
      }

      axios(config)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    })

    setRequests([])
  }

  const mainSettings = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
        }}
      >
        <p
          style={{
            width: "90%",
            marginTop: "40px",
            color: "white",
            fontSize: "26px",
            fontWeight: "bold",
            fontFamily: "Work Sans"
          }}
        >
          {"< Settings"}
        </p>

        <div className="settings-main">
          <div
            className="settings-innermain"
            onClick={() => {
              setCurrentMode("notifications")
              setFromHeader(false)
            }}
          >
            <p>Notifications</p>
            <img src={RightArrow} />
          </div>
          <div
            className="settings-innermain"
            onClick={() => setCurrentMode("terms")}
          >
            <p>Terms and Conditions</p>
            <img src={RightArrow} />
          </div>
          <div
            className="settings-innermain"
            onClick={() => setCurrentMode("privacy")}
          >
            <p>Privacy Policy</p>
            <img src={RightArrow} />
          </div>
          <div
            className="settings-innermain"
            onClick={() => setCurrentMode("password")}
          >
            <p>Change Password</p>
            <img src={RightArrow} />
          </div>

          <div className="logouts-setting" onClick={logOut}>
            <p style={{ fontSize: "14px", cursor: "pointer" }}>Logout</p>
          </div>
        </div>
      </div>
    )
  }

  const notifications = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
        }}
      >
        <p
          style={{
            width: "90%",
            marginTop: "40px",
            color: "white",
            fontSize: "26px",
            fontWeight: "bold",
            fontFamily: "Work Sans",
            cursor: "pointer"
          }}
          onClick={() => setCurrentMode("main")}
        >
          {"< Notifications"}
        </p>
        <div
          style={{
            display: "flex",
            alignSelf: "flex-start",
            marginLeft: "5%",
            alignItems: "center"
          }}
        >
          <Switch
            checked={turnNoti}
            onChange={() => setTurnNoti(!turnNoti)}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <p style={{ color: "white" }}>{`Turn ${
            turnNoti ? "off" : "on"
          } Notifications`}</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "90%",
            flexWrap: "wrap"
          }}
        >
          <div className="main-left-not">
            <div className="main-left-not-top">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center"
                }}
              >
                <p style={{ fontSize: 20 }}>New</p>
                <p style={{ marginLeft: "10px" }}>{lengths}</p>
              </div>
              <div>
                <p
                  style={{ color: "gray", cursor: "pointer" }}
                  onClick={multipleRead}
                >
                  Mark as read
                </p>
              </div>
            </div>

            <div className="not-main">
              {notificationList.map(a => {
                if (a.inChecked === false) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90%",
                        height: "80px",
                        borderBottom: "1px solid #777777",
                        marginTop: 10,
                        marginBottom: 10
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "70%",
                          alignItems: "center"
                        }}
                      >
                        <img
                          src={ProfileImg}
                          height={40}
                          width={40}
                          style={{ borderRadius: "80px" }}
                        />
                        <p style={{marginLeft:10, fontSize:12}}>{a.description}</p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <Form.Check
                          type="checkbox"
                          value={a.inChecked}
                          onClick={() => {
                            // setCurrentBox(a)
                            // setOpen(true)
                            setRequests([...requests, a])
                          }}
                        />
                        <p style={{ marginLeft: "15px" }}>{a.time}</p>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
          <div className="main-left-not" style={{ marginLeft: "30px" }}>
            <div className="main-left-not-top">
              <p style={{ fontSize: "20px" }}>Reviewed</p>
            </div>

            <div className="not-main">
              {notificationList.map(a => {
                if (a.inChecked === true) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90%",
                        height: "80px",
                        borderBottom: "1px solid #777777",
                        marginTop: 10,
                        marginBottom: 10
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "70%",
                          alignItems: "center"
                        }}
                      >
                        <img
                          src={ProfileImg}
                          height={40}
                          width={40}
                          style={{ borderRadius: "80px" }}
                        />
                        <p style={{marginLeft:10, fontSize:12}}>{a.description}</p>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <p style={{ marginLeft: "15px" }}>{a.time}</p>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const terms = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
        }}
      >
        <p
          style={{
            width: "90%",
            marginTop: "40px",
            color: "white",
            fontSize: "26px",
            fontWeight: "bold",
            fontFamily: "Work Sans",
            cursor: "pointer"
          }}
          onClick={() => setCurrentMode("main")}
        >
          {"< Terms and Conditions"}
        </p>
        <div className="settings-main">
          {termsCondition.length > 0 ? (
            termsCondition.map(p => {
              return <p style={{ width: "93%", color: "white" }}>{p.body}</p>
            })
          ) : (
            <>
              <p style={{ width: "93%", color: "white" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit , sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p style={{ width: "93%", color: "white" }}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                sit amet, consectetur, adipisci velit, sed quia non numquam eius
                modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                voluptatem. Ut enim ad minima veniam, quis nostrum
                exercitationem ullam corporis suscipit laboriosam, nisi ut
                aliquid ex ea commodi consequatur? Quis autem vel eum iure
                reprehenderit qui in ea voluptate velit esse quam nihil
                molestiae nsequatur, vel illum qui dolorem eum fugiat quo v
                oluptas nulla pariatur?
              </p>
            </>
          )}
        </div>
      </div>
    )
  }

  const privacy = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
        }}
      >
        <p
          style={{
            width: "90%",
            marginTop: "40px",
            color: "white",
            fontSize: "26px",
            fontWeight: "bold",
            fontFamily: "Work Sans",
            cursor: "pointer"
          }}
          onClick={() => setCurrentMode("main")}
        >
          {"< Privacy Policy"}
        </p>
        <div className="settings-main">
          {privacyPolicy.length > 0 ? (
            privacyPolicy.map(p => {
              return <p style={{ width: "93%", color: "white" }}>{p.body}</p>
            })
          ) : (
            <>
              <p style={{ width: "93%", color: "white" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit , sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p style={{ width: "93%", color: "white" }}>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                sit amet, consectetur, adipisci velit, sed quia non numquam eius
                modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                voluptatem. Ut enim ad minima veniam, quis nostrum
                exercitationem ullam corporis suscipit laboriosam, nisi ut
                aliquid ex ea commodi consequatur? Quis autem vel eum iure
                reprehenderit qui in ea voluptate velit esse quam nihil
                molestiae nsequatur, vel illum qui dolorem eum fugiat quo v
                oluptas nulla pariatur?
              </p>
            </>
          )}
        </div>
      </div>
    )
  }

  const password = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%"
        }}
      >
        <p
          style={{
            width: "90%",
            marginTop: "40px",
            color: "white",
            fontSize: "26px",
            fontWeight: "bold",
            fontFamily: "Work Sans",
            cursor: "pointer"
          }}
          onClick={() => setCurrentMode("main")}
        >
          {"< Change Password"}
        </p>
        <div className="settings-main-short">
          <input
            type="text"
            placeholder="Current Password"
            className="input-sign-new"
            style={{ marginTop: "40px" }}
          />
          <input
            type="text"
            placeholder="New Password"
            className="input-sign-new"
          />
          <input
            type="text"
            placeholder="Confirm New Password"
            className="input-sign-new"
          />

          <button className="login-btn-small">Change Password</button>
        </div>
      </div>
    )
  }

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        {currentMode === "main"
          ? mainSettings()
          : currentMode === "notifications"
          ? notifications()
          : currentMode === "terms"
          ? terms()
          : currentMode === "privacy"
          ? privacy()
          : currentMode === "password"
          ? password()
          : null}
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to mark this notification as read
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Do not mark as Read
          </Button>
          <Button onClick={markedForRead} color="primary" autoFocus>
            Mark as Read
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Settings
