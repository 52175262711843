import React, { useEffect, useState, useContext } from "react"

import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"
import "./ClaimProfile.css"
import axios from "axios"
import { CircularProgress } from "@material-ui/core"

import { useNavigate, useParams } from "react-router-dom"

const ClaimProfile = () => {
  const [emailObj, setEmailObj] = useState({
    email: ""
  })

  const navigation = useNavigate()

  const [id, setId] = useState(null)
  const [finalLoading, setFinalLoading] = useState(false)
  const [updateObj, setUpdateObj] = useState({})
  const [loading, setLoading] = useState(true)
  const [accessToken, setAccessToken] = useState('')

  let { email, password } = useParams()

  console.log('at is',accessToken )
  console.log("id is",id)

  console.log('the email is',email, 'the pass is',password)

  useEffect(()=>{
    let JSONed = JSON.stringify({
      email:email,
      password:password
    })

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/login/",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed
    }

    axios(config)
      .then(response => {
        console.log(response.data)

        // localStorage.setItem("@storage_Key", response.data.access_token)
        // localStorage.setItem(
        //   "@storage_Object",
        //   JSON.stringify(response.data.user)
        // )
        setAccessToken(response.data.access_token)
        setId(response.data.user.id)
        setLoading(false)
        // alert("Login Successfull")
        
      })
      .catch(err => {
        console.log(err.response.data)

        if (
          err?.response?.data?.non_field_errors !== undefined ||
          err?.response?.data?.non_field_errors.length > 0
        ) {
          
          alert(err?.response?.data?.non_field_errors[0])
        } else {
        
          alert(
            "Something went wrong, please check your internet connection or the link may nbe broken"
          )
        }
      })
  },[])

  const update = () => {

    if (updateObj.password1 !== updateObj.password2) {
      alert("Passwords do not match")
      return
    }

    setFinalLoading(true)

    let transferObj = JSON.stringify({
      old_password:password,
      new_password: updateObj.password1,
      new_password2: updateObj.password2
    })

    var config = {
      method:'PUT',
      url:`https://ready-shout-30574.botics.co/users/api/change_password/${id}/`,
      headers: {
        "content-type":"application/json",
        Authorization:`Bearer ${accessToken}`
      },
      data:transferObj
    }

    axios(config)
      .then((resp)=>{
        alert("Account Claimed Successfully")
        setFinalLoading(false)
        navigation("/sign-in")
      })
      .catch((err)=>{
        console.log(err)
        alert(err)
        setFinalLoading(false)
      })

  }

  

  // const resetFunction = () => {
  //   if (emailObj.email === "") {
  //     alert("Email cannot be empty")
  //     return
  //   }

  //   let JSONed = JSON.stringify(emailObj)

  //   var config = {
  //     method: "POST",
  //     url: "https://ready-shout-30574.botics.co/users/api/password/reset/",
  //     headers: {
  //       "Content-type": "application/json"
  //     },
  //     data: JSONed
  //   }

  //   axios(config)
  //     .then(resp => {
  //       console.log(resp.data)
  //       alert("Check you email for token inorder to create your password")
  //       navigation("/token")
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       alert(
  //         "The email you were invited with doesn't exist on the system, please contact administration"
  //       )
  //     })
  // }

  return (
    <div className="main-landing">
      <img src={rectangle} className="abs-rect" />

      <div className="landing-middle-left">
        <img src={logo} className="sign-logo" />

        <div className="sign-middle">
          {loading ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (
            <div className="sign-middle-left">
              <p
                style={{
                  color: "white",
                  fontFamily: "Work Sans",
                  fontWeight: "bold",
                  fontSize: 24
                }}
                onClick={()=> console.log('the id is',id)}
              >
                Create Password
              </p>
              <p style={{ color: "white" }}>Please create your password</p>
              <div>
                <input
                  type="text"
                  placeholder="Password"
                  className="input-sign"
                  onChange={e =>
                    setUpdateObj({ ...updateObj, password1: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Confirm Password"
                  className="input-sign"
                  onChange={e =>
                    setUpdateObj({ ...updateObj, password2: e.target.value })
                  }
                />
              </div>

              {
                finalLoading ? (
                  <CircularProgress style={{color:'white',alignSelf:'center'}} />
                ):(
                  <button className="login-btn" onClick={update}>
                Create
              </button>
                )
              }
              
              {/* <p
                style={{
                  textAlign: "center",
                  color: "#57b100",
                  fontWeight: "bold"
                }}
                onClick={() => {
                  navigation("/sign-in")
                }}
              >
                Cancel
              </p> */}
            </div>
          )}

          {/* <div className="sign-middle-left">
            <p
              style={{
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: "bold",
                fontSize: 24
              }}
            >
              ClaimProfile
            </p>

            <p style={{ color: "white" }}>
              Please enter the email address you were invited with
            </p>
            <div>
              <input
                type="text"
                placeholder="Email Address/ Phone Number"
                className="input-sign"
                onChange={e =>
                  setEmailObj({ ...emailObj, email: e.target.value })
                }
              />
            </div>

            <button
              className="login-btn"
              style={{ cursor: "pointer" }}
              onClick={resetFunction}
            >
              Submit
            </button>
            <p
              style={{
                textAlign: "center",
                color: "#57b100",
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => {
                navigation("/sign-in")
              }}
            >
              Cancel
            </p>
          </div> */}
        </div>
      </div>

      <img src={laptop} className="landing-middle-right" />
    </div>
  )
}

export default ClaimProfile
