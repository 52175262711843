import React, { useContext, useState, useEffect } from "react"

import { Routes, Route, useNavigate } from "react-router-dom"
import axios from "axios"
// import Home from "../screens/Home"
import Home from "../pages/Home/Home"
import Landing from "../pages/Landing/Landing"
// import Signin from "../screens/Signin"
import Signin from "../pages/Signin/Signin"
import Signup from "../pages/Signup/Signup"
// import Signup from "../screens/Signup"
// import TockenInput from "../screens/TockenInput"
// import UpdatePassword from "../screens/UpdatePassword"
import UpdatePassword from "../pages/UpdatePassword/UpdatePassword"
import PasswordReset from "../pages/PasswordReset/PasswordReset"
import TokenInput from "../pages/TokenInput/TokenInput"
import Campaigns from "../pages/Campaigns/Campaigns"
import Contacts from "../pages/Contacts/Contacts"
import Testimonials from "../pages/Testimonials/Testimonials"
import Settings from "../pages/Settings/Settings"
import MyProfile from "../pages/MyProfile/MyProfile"
import CreateCampaign from "../pages/CreateCampaign/CreateCampaign"
import RequestTestimonial from "../pages/RequestTestimonial/RequestTestimonial"
import StaffMembers from "../pages/StaffMembers/StaffMembers"
import AddTestimonials from "../pages/AddTestimonials/AddTestimonials"
import ClaimProfile from "../pages/ClaimProfile/ClaimProfile"
import AddStaff from "../pages/AddStaff/AddStaff"
import { TokenContext } from "../context/TokenContext"
import AddContact from "../pages/AddContact/AddContact"
import Coupons from "../pages/Coupons/Coupons"
import AddCoupon from "../pages/AddCoupon/AddCoupon"
import Performance from "../pages/Performance/Performance"
import TestLogin from "../pages/TestLogin/TestLogin"
import UserProfile from "../services/AuthCheckerService"

const Navigation = () => {
  const {
    user,
    setUser,
    token,
    setToken,
    profileData,
    setProfileData,
    globalRule,
    setGlobalRole
  } = useContext(TokenContext)
  const navigation = useNavigate()

  const logOut = async () => {
    try {
      localStorage.removeItem("@storage_Key")
      localStorage.removeItem("@storage_Object")
      localStorage.removeItem("@google_Name")
      setToken("")
      setUser({})
      navigation("/sign-in")
    } catch (e) {
      console.log(e)
      alert("An Error Occured")
    }
  }


  const parseJwt = token => {
    try {
      return atob(token.split(".")[1])
    } catch (e) {
      return null
    }
  }

  useEffect(() => {
    let AuthChecker = UserProfile.getSession()

    if (
      AuthChecker === "" ||
      AuthChecker === null ||
      AuthChecker === undefined
    ) {
      // navigation("/sign-in")
    } else {
      const value = localStorage.getItem("@storage_Key")
      const user_value = JSON.parse(localStorage.getItem("@storage_Object"))
      const user_role = localStorage.getItem("@storage_Role")

      let response = parseJwt(value)
      let newResp = JSON.parse(response)
      console.log('exppire response is',newResp)
      if (newResp.exp * 1000 < Date.now()) {
        console.log('check world')
        logOut()
      } else {
        console.log('hello world')
        setToken(value)
        setUser(user_value)
        getProfileInfo()
      }
    }
  }, [UserProfile, token])

  const getProfileInfo = () => {
    var secondConfig = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/users/api/profile/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }
    axios(secondConfig)
      .then(response => {
        console.log(response.data)
        if (response.data.role === "" || response.data.role === "string") {
          setGlobalRole("user")
          localStorage.setItem("@storage_Role", "user")
          setProfileData(response.data)
        } else {
          setGlobalRole(response.data.role)
          localStorage.setItem("@storage_Role", response.data.role)
          setProfileData(response.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <Routes>
        {/* {
          token !== null || token !== undefined || token !== "" ? ():()
        } */}
        <Route path="/" element={<Landing />} />
        <Route path="/sign-in" element={<Signin />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/token" element={<TokenInput />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/claim-profile/:email/:password"
          element={<ClaimProfile />}
        />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/home" element={<Home />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/staff-members" element={<StaffMembers />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/add-staff" element={<AddStaff />} />
        <Route path="/add-contact" element={<AddContact />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/create-campaign" element={<CreateCampaign />} />
        <Route path="/request-testimonial" element={<RequestTestimonial />} />
        <Route path="/add-coupon" element={<AddCoupon />} />
        <Route
          path="/add-testimonial/:id/:testid/:userid"
          element={<AddTestimonials />}
        />
        <Route path="/performance" element={<Performance />} />
        <Route path="/testlogin/:id" element={<TestLogin />} />
      </Routes>
    </>
  )
}

export default Navigation
