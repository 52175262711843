import React, { useState, useEffect, createContext } from "react"

export const TokenContext = createContext()

export const TokenProvider = props => {
  //default value admin
  const [token, setToken] = useState("")
  const [user, setUser] = useState({})
  const [nameInfo, setNameInfo] = useState({
    FName: ""
  })
  const [globalRole, setGlobalRole] = useState("")
  const [eid, setEid] = useState("")
  const [color, setColor] = useState("#111")
  const [profileData, setProfileData] = useState({})

  useEffect(() => {
    if (
      nameInfo?.FName.charAt(0) === "a" ||
      nameInfo?.FName.charAt(0) === "A"
    ) {
      setColor("#fc0303")
    }

    if (
      nameInfo?.FName.charAt(0) === "b" ||
      nameInfo?.FName.charAt(0) === "B"
    ) {
      setColor("#fc2803")
    }
    if (
      nameInfo?.FName.charAt(0) === "c" ||
      nameInfo?.FName.charAt(0) === "C"
    ) {
      setColor("#fc4103")
    }
    if (
      nameInfo?.FName.charAt(0) === "d" ||
      nameInfo?.FName.charAt(0) === "D"
    ) {
      setColor("#fc6203")
    }
    if (
      nameInfo?.FName.charAt(0) === "e" ||
      nameInfo?.FName.charAt(0) === "E"
    ) {
      setColor("#fc8403")
    }
    if (
      nameInfo?.FName.charAt(0) === "f" ||
      nameInfo?.FName.charAt(0) === "F"
    ) {
      setColor("#fca903")
    }
    if (
      nameInfo?.FName.charAt(0) === "g" ||
      nameInfo?.FName.charAt(0) === "G"
    ) {
      setColor("#fcdf03")
    }
    if (
      nameInfo?.FName.charAt(0) === "h" ||
      nameInfo?.FName.charAt(0) === "H"
    ) {
      setColor("#dffc03")
    }
    if (
      nameInfo?.FName.charAt(0) === "i" ||
      nameInfo?.FName.charAt(0) === "I"
    ) {
      setColor("#a5fc03")
    }
    if (
      nameInfo?.FName.charAt(0) === "j" ||
      nameInfo?.FName.charAt(0) === "J"
    ) {
      setColor("#45fc03")
    }
    if (
      nameInfo?.FName.charAt(0) === "k" ||
      nameInfo?.FName.charAt(0) === "K"
    ) {
      setColor("#03fc24")
    }
    if (
      nameInfo?.FName.charAt(0) === "l" ||
      nameInfo?.FName.charAt(0) === "L"
    ) {
      setColor("#03fc88")
    }
    if (
      nameInfo?.FName.charAt(0) === "m" ||
      nameInfo?.FName.charAt(0) === "M"
    ) {
      setColor("#03fcc2")
    }
    if (
      nameInfo?.FName.charAt(0) === "n" ||
      nameInfo?.FName.charAt(0) === "N"
    ) {
      setColor("#03fce7")
    }
    if (
      nameInfo?.FName.charAt(0) === "o" ||
      nameInfo?.FName.charAt(0) === "O"
    ) {
      setColor("#03f0fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "p" ||
      nameInfo?.FName.charAt(0) === "P"
    ) {
      setColor("#03a1fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "q" ||
      nameInfo?.FName.charAt(0) === "Q"
    ) {
      setColor("#03cafc")
    }
    if (
      nameInfo?.FName.charAt(0) === "r" ||
      nameInfo?.FName.charAt(0) === "R"
    ) {
      setColor("#0377fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "s" ||
      nameInfo?.FName.charAt(0) === "S"
    ) {
      setColor("#0341fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "t" ||
      nameInfo?.FName.charAt(0) === "T"
    ) {
      setColor("#3103fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "u" ||
      nameInfo?.FName.charAt(0) === "U"
    ) {
      setColor("#7303fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "v" ||
      nameInfo?.FName.charAt(0) === "V"
    ) {
      setColor("#a503fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "w" ||
      nameInfo?.FName.charAt(0) === "W"
    ) {
      setColor("#0341fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "x" ||
      nameInfo?.FName.charAt(0) === "X"
    ) {
      setColor("#e303fc")
    }
    if (
      nameInfo?.FName.charAt(0) === "y" ||
      nameInfo?.FName.charAt(0) === "Y"
    ) {
      setColor("#fc03ba")
    }
    if (
      nameInfo?.FName.charAt(0) === "z" ||
      nameInfo?.FName.charAt(0) === "Z"
    ) {
      setColor("#fc036b")
    }
  }, [nameInfo])

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        eid,
        setEid,
        nameInfo,
        setNameInfo,
        color,
        setColor,
        globalRole,
        setGlobalRole,
        profileData,
        setProfileData
      }}
    >
      {props.children}
    </TokenContext.Provider>
  )
}
