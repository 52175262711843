import React, { useState, useEffect, createContext } from "react"

export const ResetTokenContext = createContext()

export const ResetTokenProvider = props => {
  //default value admin
  const [resToken, setResToken] = useState("")

  // it can be home || campaign || test || contacts || staff || profile || settings

  return (
    <ResetTokenContext.Provider value={{ resToken, setResToken }}>
      {props.children}
    </ResetTokenContext.Provider>
  )
}