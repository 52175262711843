import React, { useState, useEffect, useContext } from "react"

import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import logo from "../../assets/images/logo.png"
import { TokenContext } from "../../context/TokenContext"
import { useNavigate } from "react-router-dom"
import axios from "axios"

import Pagination from "../Components/Pagination"
import ProfileImg from "../../assets/images/usernew.png"

const Performance = () => {
  const { user, setUser, token, setToken, profileData } =
    useContext(TokenContext)

  const [team, setTeam] = useState([])
  const [upgradedMembers, setUpgradedMembers] = useState([])
  const [campaigns, setCampaigns] = useState([])

  console.log("user id is", profileData)

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      return
    }

    var config = {
      method: "GET",
      url: `https://ready-shout-30574.botics.co/allUsers/api/allUsers/${user.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }

    axios(config)
      .then(resp => {
        setTeam(resp.data.staffMembers)
      })
      .catch(err => {
        alert("Please try again later")
      })
  }, [token])

  // const getStaffDetails = async id => {
  //   var superConfig = {
  //     method: "GET",
  //     url: `https://ready-shout-30574.botics.co/allUsers/api/allUsers/${id}/`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`
  //     }
  //   }

  //   try {
  //     let response = await axios(superConfig)
  //     return response
  //   } catch (err) {
  //     let response = err
  //     return response
  //   }
  // }

  // useEffect(async () => {
  //   const fetcher = async () => {
  //     let modifiedArray = []

  //     for (const each of profileData?.staffMembers) {
  //       let newresponse = await getStaffDetails(each.staffID)

  //       modifiedArray.push({
  //         email: newresponse.data.email,
  //         id: newresponse.data.id,
  //         role: newresponse.data.role,
  //         username: newresponse.data.username,
  //         FName: newresponse.data.FName,
  //         LName: newresponse.data.lName,
  //         CompanyName: newresponse.data.CompanyName,
  //         phone: newresponse.data.phone,
  //         country: newresponse.data.country,
  //         city: newresponse.data.city,
  //         state: newresponse.data.state,
  //         zip: newresponse.data.zip,
  //         PrimaryAddress: newresponse.data.PrimaryAddress,
  //         SecondaryAddress: newresponse.data.SecondaryAddress,
  //         reciveTestimonial: newresponse.data.reciveTestimonial,
  //         contacts: newresponse.data.contacts,
  //         requestTestimonial: newresponse.data.reciveTestimonial,
  //         reieveCoupons: newresponse.data.reieveCoupons,
  //         // notifications: [],
  //         // staffMembers: []
  //       })
  //     }
  //     return modifiedArray
  //   }

  //   let resp = await fetcher()

  //   let mirrorFilter = resp.filter(finalResult => {
  //     return finalResult.email !== user.email
  //   })

  //   console.log(mirrorFilter)

  //   let tempData = mirrorFilter

  //   tempData.forEach(element => {
  //     element.isOpen = false
  //   })

  //   tempData.forEach((element, id) => {
  //     element.index = id
  //   })

  //   tempData.forEach(element => {
  //     element.isEdit = false
  //   })

  //   setStaffMembers(tempData)
  // }, [token])

  // console.log("staff mem are", staffMembers)

  // useEffect(() => {
  //   getCampaign()
  // }, [])

  // useEffect(() => {
  //   var config = {
  //     method: "GET",
  //     url: "https://ready-shout-30574.botics.co/allUsers/api/allUsers/?limit=1000000000",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
  //     }
  //   }

  //   axios(config)
  //     .then(resp => {
  //       console.log(resp.data.results)

  //       setUpgradedMembers(resp.data.results)

  //       let filteredTests = resp.data.results.filter(result => {
  //         return result.CompanyName === profileData.CompanyName
  //       })

  //       setStaffMembers(filteredTests)
  //       // const arr = Array(resp.data.results.length)
  //       // arr.fill(false)
  //       // setArrayOfBooleans(arr)
  //     })
  //     .catch(err => {
  //       console.log(err.message)
  //       alert("An error occured")
  //     })
  // }, [])

  console.log("profile data is", team)

  const getCampaign = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/campaign/api/campaign/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)
        setCampaigns(resp.data.results)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }

  const navigation = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = profileData.staffMembers?.slice(
    indexOfFirstPost,
    indexOfLastPost
  )

  console.log("my team is", team)

  const lengthCalculator = (array, type) => {
    if (array.length === 0) {
      return 0
    } else {
      
      let finalArray = []

      array.forEach((each)=> {
        if (type === "request") {
         let subRes = [] 
         subRes = each.campaignTestimonial.filter((final)=> final.status === false || final.status === true)
          console.log('subres is', subRes)
          finalArray = finalArray.concat(subRes)

        } else if (type === "receive") {
          let subRec = each.campaignTestimonial.filter((final)=> final.status === true)
          console.log('subrec is',subRec)
          finalArray = finalArray.concat(subRec)
        }
      })
     console.log(finalArray)
      return finalArray.length
    }
  }

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%"
            }}
          >
            <p
              style={{
                width: "50%",
                marginTop: "40px",
                color: "white",
                fontSize: "26px",
                fontWeight: "bold",
                fontFamily: "Work Sans"
              }}
            >
              {`< Staff Members / Team Performance`}
            </p>
          </div>
        </div>
        {/*globalRole !== "user" */}

        <p style={{ color: "white", marginLeft: "7%" }}>{`${
          profileData.CompanyName || "Company"
        }'s Staffs`}</p>
        {true ? (
          <table className="coupon-table">
            <tr>
              <th></th>
              <th style={{ textAlign: "center" }}>Name</th>
              <th style={{ textAlign: "center" }}>No. of Contacts Added</th>
              <th style={{ textAlign: "center" }}>
                No. of testimonials Requests Sent
              </th>
              <th style={{ textAlign: "center" }}>
                No. of Testimonial Received
              </th>
              <th style={{ textAlign: "center" }}>%age conversion</th>
            </tr>

            {/*member.role === "Manager" || member.role === "Admin" */}
            {currentPosts?.map((member, index) => {
              if (member.role !== "Unmatch") {
                return (
                  <tr
                    style={{
                      background: "#2D2C2C",
                      height: "80px",
                      borderBottom: "1px solid gray"
                    }}
                  >
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={ProfileImg}
                        style={{
                          marginLeft: "20px",
                          height: "35px",
                          width: "35px"
                        }}
                      />
                    </td>

                    <td style={{ textAlign: "center" }}>
                      {member.staffID.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {member.staffID.contacts.length || 0}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {lengthCalculator(member?.staffID?.campaigns,"request")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {lengthCalculator(member?.staffID?.campaigns,"receive")}
                    </td>
                    {
                      member.staffID.campaigns.length > 0 ?
                      <td style={{ textAlign: "center" }}>{ lengthCalculator(member?.staffID?.campaigns,"request") !== 0 ? `${
                        ((lengthCalculator(member?.staffID?.campaigns,"receive") / lengthCalculator(member?.staffID?.campaigns,"request"))*100).toFixed(2)
                      }${'%'}`: '0%'
                    }</td>:(
                      <td style={{ textAlign: "center" }}>
                        0%
                      </td>
                    )
                    }
                    
                  </tr>
                )
              }
            })}
          </table>
        ) : (
          <div>
            <p style={{ color: "white", textAlign: "center" }}>
              You do not have the right privilege to access staff members, only
              an admin or moderator can access
            </p>
          </div>
        )}

        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={team?.length}
          paginate={paginate}
        />
      </div>
    </>
  )
}

export default Performance
