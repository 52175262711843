import React, { useState, useEffect, useContext } from "react"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import { DropzoneArea } from "material-ui-dropzone"
import "./CreateCampaign.css"
import { TokenContext } from "../../context/TokenContext"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { CircularProgress } from "@material-ui/core"

const CreateCampaign = () => {
  const { token, setToken, user, setUser } = useContext(TokenContext)
  const [open2, setOpen2] = useState(false)
  const navigation = useNavigate()
  const [state, setState] = useState({})

  const [vidObj, setVidObj] = useState({
    video: "",
    email: JSON.parse(localStorage.getItem("@storage_Object")).email,
    VidName: "",
    description: "",
    inactive: "Active",
    userID:user.id
  })

  console.log('the user id here is',vidObj)
  console.log(localStorage.getItem("@storage_Key"))
  const [loading, setLoading] = useState(false)

  const [vid, setVid] = useState("")

  const handleChange = files => {
    console.log(files[0])

    if (files[0] === undefined) {
      return
    }
    let obj = {
      uri: files[0].path,
      type: files[0].type,
      name: files[0].name
    }

    const data = new FormData()
    console.log(token)

    data.append("document", files[0])

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/uploader/api/uploader/",
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: data
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.document.split("?")[0])
        setVid(resp.data.document.split("?")[0])
        setVidObj({
          ...vidObj,
          video: resp.data.document.split("?")[0]
        })
      })
      .catch(err => {
        console.log("Afnan" + err.message)
      })
  }

  console.log(user.external_id)

  const newCampaign = () => {

    if(vidObj.description === "" || vidObj.VidName === "") {
      alert("Please fill all the fields")
      return
    }

    let veo = { ...vidObj }



    if (vidObj.video === "") {
      veo.video = "string"
    }

    veo.key = user.external_id

    let JSONed = JSON.stringify(veo)
    console.log(vidObj)

    console.log(JSONed)

    setLoading(true)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/campaign/api/campaign/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        // alert("Campaign uploaded Successfully!")
        setState(resp.data)
        setOpen2(true)
        setLoading(false)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
        setLoading(false)
      })
  }

  console.log(token)

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <p
            style={{
              width: "90%",
              marginTop: "40px",
              color: "white",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Work Sans"
            }}
          >
            Create a new campaign
          </p>
          <div className="new-cam-main">
            {/* <div className="cam-dropzone">
              {vid === "" ? (
                <DropzoneArea
                  onChange={handleChange}
                  dropzoneText="Upload Video"
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  previewText="Selected Video"
                  filesLimit={1}
                />
              ) : (
                <embed type="video/webm" src={vid} width="800" height="400" />
              )}
            </div> */}
            <div className="cam-right">
              <div classNam3e="cam-right-each">
                <p>Name of the Video</p>
                <input
                  placeholder="Campaign number 1"
                  type="text"
                  className="input-sign"
                  onChange={e =>
                    setVidObj({ ...vidObj, VidName: e.target.value })
                  }
                />
              </div>
              <div>
                <p>Description</p>
                <textarea
                  placeholder="Add Description"
                  type="text"
                  className="input-sign-area"
                  onChange={e =>
                    setVidObj({ ...vidObj, description: e.target.value })
                  }
                />
              </div>
              <div className="btn-cam-add"  onClick={newCampaign}>
                {
                  loading ? (
                    <CircularProgress color="white" style={{color:'white', height:'25px', width:'25px'}}/>
                  ):(
                    <p>Save</p>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Campaign Uploaded Successfully"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => navigation("/campaigns")} color="primary">
            Skip for now
          </Button>
          <Button
            onClick={() => navigation("/request-testimonial",{
              state: state
            })}
            color="primary"
          >
            Request Testimonials
          </Button>
          <Button
            onClick={() => navigation("/campaigns")}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateCampaign
