import React, { useState, useEffect, useContext } from "react"
import { Offcanvas } from "react-bootstrap"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import logo from "../../assets/images/logo.png"
import { TokenContext } from "../../context/TokenContext"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Fade } from "react-reveal"

const Home = () => {
  const { user, setUser, token, setToken } = useContext(TokenContext)

  const navigation = useNavigate()

  useEffect(() => {
    const getData = () => {
      try {
        console.log("effect check")
        const value = localStorage.getItem("@storage_Key")
        const user_value = localStorage.getItem("@storage_Object")
        console.log(`the value of token is ${value}`)
        if (value !== null || user_value !== null) {
          // console.log("Token: " + value)
          // console.log("User Object: " + JSON.parse(user_value).id)
          setToken(value)
          setUser(JSON.parse(user_value))
        } else {
          setToken("")
          setUser({})
          navigation("/sign-in")
        }
      } catch (e) {}
    }
    getData()
  }, [])

  console.log(user)

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", background: "#3b3c3a" }}
      >
        {/*Left sidebar */}
        {SideMenu()}
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {Header()}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }}
          >
            <p
              style={{
                width: "90%",
                marginTop: "40px",
                color: "white",
                fontSize: "26px",
                fontWeight: "bold",
                fontFamily: "Work Sans"
              }}
            >
              {`Home`}
            </p>
            <Fade top>
              <div
                className="landing-middle-left"
                style={{ marginTop: "100px" }}
              >
                <img src={logo} className="landing-logo" />

                <p className="landing-txt">
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.”
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
