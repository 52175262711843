import React, { useEffect, useState, useContext } from "react"

import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"
import "./PasswordReset.css"
import axios from "axios"

import { useNavigate } from "react-router-dom"

const PasswordReset = () => {
  const [emailObj, setEmailObj] = useState({
    email: ""
  })

  const navigation = useNavigate()

  const resetFunction = () => {
    if (emailObj.email === "") {
      alert("Email cannot be empty")
      return
    }

    let JSONed = JSON.stringify(emailObj)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/password/reset/",
      headers: {
        "Content-type": "application/json"
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        alert("Password Reset Email sent")
        navigation("/token")
      })
      .catch(err => {
        console.log(err)
        alert("The email doesn't exist on the system")
      })
  }

  return (
    <div className="main-landing">
      <img src={rectangle} className="abs-rect" />

      <div className="landing-middle-left">
        <img src={logo} className="sign-logo" />

        <div className="sign-middle">
          <div className="sign-middle-left">
            <p
              style={{
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: "bold",
                fontSize: 24
              }}
            >
              Password Reset
            </p>

            <p style={{ color: "white" }}>
              Please enter your email address or phone number in order to reset
              your password
            </p>
            <div>
              <input
                type="text"
                placeholder="Email Address/ Phone Number"
                className="input-sign"
                onChange={e =>
                  setEmailObj({ ...emailObj, email: e.target.value })
                }
              />
            </div>

            <button
              className="login-btn"
              style={{ cursor: "pointer" }}
              onClick={resetFunction}
            >
              Submit
            </button>
            <p
              style={{
                textAlign: "center",
                color: "#57b100",
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => {
                navigation("/sign-in")
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      </div>

      <img src={laptop} className="landing-middle-right" />
    </div>
  )
}

export default PasswordReset
