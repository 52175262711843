import React from "react"
import "./Landing.css"
import rectangle from "../screens/images/rectangle.png"
import laptop from "../screens/images/laptop.png"
import logo from "../screens/images/logo.png"
import { Form, Button } from "react-bootstrap"
import "./UpdatePassword.css"

function UpdatePassword() {
  return (
    <div
      style={{
        backgroundColor: "#393a39",
        width: "100%",
        height: "100vh",
        overflowY: "hidden"
      }}
    >
      {" "}
      <div
        style={{
          position: "fixed",
          right: "-10px",
          top: "0px"
        }}
      >
        <img src={rectangle} alt="rectangle" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            position: "fixed",
            right: "-4px",
            top: "0px",
            width: "100%",
            marginTop: "6%"
          }}
        >
          <div style={{ marginLeft: "50px" }}></div>
          <div>
            <img
              src={logo}
              alt="logo"
              style={{
                height: "100px",
                width: "600px"
              }}
            />
            <div className="form">
              <h1 className="signup">Update Password</h1>
              <div className="text">
                <p >Please enter your new password</p>
              </div>
             
              <Form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <Form.Group className="textField" >
                  <Form.Control
                    placeholder="New Password"
                    className="textField"
                  />
                </Form.Group>
                <div style={{ marginTop: "20px" }}></div>
                <Form.Group className="textField" >
                  <Form.Control
                    placeholder="Confirm New Password"
                    className="textField"
                  />
                </Form.Group>
                <div style={{ marginTop: "30px" }}></div>
                <Button className="submit1" type="submit">
                Update
                </Button>
                <div style={{ marginTop: "20px" }}></div>
                <Button className="cancel" type="submit">
                      Cancel
                </Button>
                      
                     
               
                
              </Form>
            </div>
          </div>
          <img src={laptop} alt="laptop" style={{ width: "50%" }} />
        </div>
      </div>
    </div>
  )
}

export default UpdatePassword
