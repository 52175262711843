import React from "react"

var UserProfile = (function () {
  const getSession = () => {
    return localStorage.getItem("@storage_Key")
  }

  return {
    getSession: getSession
  }
})()

export default UserProfile
