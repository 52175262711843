import React, { Component } from "react";
import { withRouter } from "react-router-dom";
const parseJwt = (token) => {
  try {
    return atob(token.split('.')[1]);
  } catch (e) {
    return null;
  }
};
class AuthVerify extends Component {
  constructor(props) {
    super(props);
    props.history.listen(() => {
      const user = localStorage.getItem("@storage_Key");
      if (user) {
        const decodedJwt = parseJwt(user);
        if (decodedJwt.exp * 1000 < Date.now()) {
          props.logOut();
        }
      }
    });
  }
  render() {
    return <div></div>;
  }
}
export default AuthVerify;
