import React, { useState, useEffect, useContext } from "react"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import NewCov from "../../assets/images/newcov.png"
import BigCov from "../../assets/images/bigcov.png"
import "./Testimonials.css"
import PhoneImg from "../../assets/images/phone.png"
import MsgImg from "../../assets/images/msg.png"
import FbLogo from "../../assets/images/fbsmall.png"
import TwitterLogo from "../../assets/images/twittersmall.png"
import axios from "axios"
import { FilterContext } from "../../context/FilterContext"
import ReactFlowPlayer from "react-flow-player"
import Carousel from "react-elastic-carousel"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton
} from "react-share"
const Testimonials = () => {
  const [view, setView] = useState(false)
  const [currentTest, setCurrentTest] = useState({})
  const [testimonals, setTestimonals] = useState([])
  const [selected, setSelected] = useState({})
  const [filterTxt, setFilterTxt] = useState("")
  const [campLength, setCampLength] = useState([])
  const [currentCampaign, setCurrentCampaign] = useState({key:'abc'})
  // const { filters, setFilters } = useContext(FilterContext)

  useEffect(() => {
    getTestimonials()
  }, [currentCampaign])

  useEffect(() => {
    getCampaign()
  }, [])

  console.log(currentCampaign)

  // useEffect(() => {
  //   setFilterContext("testimonial")
  // }, [])

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 1200, itemsToShow: 4 },
    { width: 1700, itemsToShow: 6 }
  ]

  const getTestimonials = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/testimonials/api/testimonials/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)
        
        let filteredResults = resp.data.results.filter((eachFilter)=> {
          return eachFilter.campaignID === currentCampaign
        })

        setTestimonals(filteredResults)        
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }

  const Txts = e => {
    console.log(e.target.value)
    setFilterTxt(e.target.value)
  }

  const getCampaign = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/campaign/api/campaign/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)

        let filteredTests = resp.data.results.filter(result => {
          return (
            result.email ===
            JSON.parse(localStorage.getItem("@storage_Object")).email
          )
        })
        
        setCampLength(filteredTests)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Header loc="testimonial" testTxt={Txts} />
        {/* {Header()} */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          {view === false ? (
            <>
              <p
                style={{
                  width: "90%",
                  marginTop: "40px",
                  color: "white",
                  fontSize: "26px",
                  fontWeight: "bold",
                  fontFamily: "Work Sans"
                }}
              >
                {`Testimonials`}
              </p>

              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start"
                }}
              >
                <p style={{ color: "white" }}>Filter By Campaigns</p>
                <select
                  id="campaigns"
                  name="Campaigns"
                  onChange={e => setCurrentCampaign(e.target.value)}
                  className="input-sign-new"
                  style={{ width: "20%" }}
                >
                  <option value="all">Select a campaign</option>
                  {campLength.map(states => (
                    <option key={states.id} value={states.key}>
                      {states.VidName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="cam-top">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    marginTop:'50px'
                  }}
                >
                  <Carousel
                    breakPoints={breakPoints}
                    showArrows={true}
                    pagination={false}
                    disableArrowsOnEnd={false}
                    enableAutoPlay={false}
                  >
                    {testimonals
                        .filter((each)=> {
                          return each.status === true
                        })
                      // .filter(firstTest =>
                      //   firstTest.CompanyName.toLowerCase().includes(
                      //     filterTxt.toLowerCase()
                      //   )
                      // )
                      // .filter(secondTest => {
                      //   if (currentCampaign === "all") {
                      //     return secondTest
                      //   } else {
                      //     return (
                      //       secondTest.CompanyName.toLowerCase() ===
                      //       currentCampaign.toLowerCase()
                      //     )
                      //   }
                      // })
                      .map(test => {
                        if (
                          // test.email ===
                          // JSON.parse(localStorage.getItem("@storage_Object"))
                          //   .email
                          test.campaignID === currentCampaign
                        ) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "22px",
                                  fontWeight: 600,
                                  color: "white"
                                }}
                              >
                                {test.CustomerName}
                              </p>
                              <div
                                className="each-cam"
                                style={{
                                  marginRight: "50px",
                                  marginBottom: "50px"
                                }}
                              >
                                <embed
                                  src={test.video}
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    borderTopRightRadius: "8px",
                                    borderTopLeftRadius: "8px"
                                  }}
                                />
                                <div className="each-cam-slim-bottom">
                                  <p>{test.CustomerName}</p>
                                  <div className="inners">
                                    <img src={PhoneImg} />
                                    <p style={{ marginLeft: "12px" }}>
                                      {test.CompanyEmail}
                                    </p>
                                  </div>
                                  <div className="inners">
                                    <img src={MsgImg} />
                                    <p style={{ marginLeft: "12px" }}>
                                      {test.CustomerPhone}
                                    </p>
                                  </div>
                                  <div
                                    className="inners-btn"
                                    onClick={() => {
                                      setView(true)
                                      // setCurrentTest({})
                                      setSelected(test)
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <p>View Testimonial</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                  </Carousel>
                </div>
              </div>
            </>
          ) : (
            <>
              <p
                style={{
                  width: "90%",
                  marginTop: "40px",
                  color: "white",
                  fontSize: "26px",
                  fontWeight: "bold",
                  fontFamily: "Work Sans",
                  cursor: "pointer"
                }}
                onClick={() => {
                  setView(false)
                }}
              >
                {`< View Testimonial`}
              </p>
              <div className="cam-top">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start"
                  }}
                >
                  <div className="each-cam-det">
                    <div className="each-cam-slim-bottom-det">
                      <div
                        style={{
                          width: "100%",
                          height: "250px",
                          borderRadius: "20px"
                        }}
                      >
                        <ReactFlowPlayer
                          playerInitScript="http://releases.flowplayer.org/7.2.1/flowplayer.min.js"
                          playerId="reactFlowPlayer"
                          className="FlowPlayer"
                          sources={[
                            {
                              type: "video/mp4",
                              src: selected.video
                            }
                          ]}
                        />
                      </div>
                      <p
                        style={{
                          fontSize: "22px",
                          fontWeight: 600,
                          paddingBottom: "20px",
                          borderBottomWidth: 1,
                          borderBottomColor: "#777",
                          borderBottomStyle: "solid",
                          width: "100%"
                        }}
                      >
                        {selected.CompanyName}
                      </p>
                      <p> {selected.CustomerName}</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          paddingBottom: "20px"
                        }}
                      >
                        <div>
                          <div
                            className="inners"
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              height: "35px"
                            }}
                          >
                            <img src={MsgImg} />
                            <p style={{ marginLeft: "12px" }}>
                              {selected.CompanyEmail}
                            </p>
                          </div>
                          <div
                            className="inners"
                            style={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              height: "35px"
                            }}
                          >
                            <img src={PhoneImg} />
                            <p style={{ marginLeft: "12px" }}>
                              {" "}
                              {selected.CustomerPhone}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p>Share to</p>
                          <div>
                            {/* <img src={FbLogo} />
                              <img
                                style={{ marginLeft: "5px" }}
                                src={TwitterLogo}
                              /> */}
                            <FacebookShareButton
                              url={selected.video}
                              quote="Check out my campaign"
                              hashtag="#ready-shout"
                            >
                              <FacebookIcon
                                size={20}
                                style={{ borderRadius: "50px" }}
                              />
                            </FacebookShareButton>
                            <TwitterShareButton
                              url={selected.video}
                              title="Check out this campaign"
                            >
                              <TwitterIcon
                                size={20}
                                style={{ borderRadius: "50px" }}
                              />
                            </TwitterShareButton>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "120px",
                          height: "35px",
                          borderRadius: "10px",
                          background: "#57b100",
                          marginBottom: "20px",
                          cursor: "pointer"
                        }}
                      >
                        <p style={{ color: "white" }}>Download</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Testimonials
