import React, { useState, useEffect, useContext } from "react"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import Fblogo from "../../assets/images/fbsmall.png"
import Twitterlogo from "../../assets/images/twittersmall.png"
import Cov from "../../assets/images/coverpic.png"
import "./Campaigns.css"
import CampImg from "../../assets/images/cammain.png"
import { Form } from "react-bootstrap"
import Bin from "../../assets/images/bin.png"
import { useNavigate } from "react-router-dom"
import TestImg from "../../assets/images/newpic.png"
import { CircularProgress } from "@material-ui/core"
import JustImg from "../../assets/images/usernew.png"
import ReactFlowPlayer from "react-flow-player"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton
} from "react-share"
import { DropzoneArea } from "material-ui-dropzone"
import axios from "axios"
import { TokenContext } from "../../context/TokenContext"
import moment from "moment"
import ProfileImg from "../../assets/images/profile.png"
import UserProfile from "../../services/AuthCheckerService"
import Pagination from "../Components/Pagination"
import PopUp from "../Components/PopUp"
import { Detector } from "react-detect-offline"

const Campaigns = () => {
  const [edit, setEdit] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  const [campLength, setCampLength] = useState([])
  const [contentLoading, setContentLoading] = useState(false)
  const navigation = useNavigate()
  const [singleCampaign, setSingleCampaign] = useState({})
  const [testimonals, setTestimonals] = useState([])
  const [filterTxt, setFilterTxt] = useState("")
  const [editState, setEditState] = useState(false)
  const [vid, setVid] = useState("")
  const {
    user,
    setUser,
    token,
    setToken,
    nameInfo,
    setNameInfo,
    globalRole,
    setGlobalRole,
    profileData,
    setProfileData
  } = useContext(TokenContext)
  const [updateCampaignFailModal, setUpdateCampaignFailModal] = useState(false)
  const [updateCampaignModal, setUpdateCampaignModal] = useState(false)

  const shareUrl = "https://www.google.com/"

  useEffect(() => {
    getProfileInfo()
  }, [])

  console.log("token is", token)

  console.log("the user is", profileData)

  const getProfileInfo = () => {
    var secondConfig = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/users/api/profile/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }
    axios(secondConfig)
      .then(response => {
        console.log(response.data)
        if (response.data.role === "" || response.data.role === "string") {
          setGlobalRole("user")
          localStorage.setItem("@storage_Role", "user")
          setProfileData(response.data)
        } else {
          setGlobalRole(response.data.role)
          localStorage.setItem("@storage_Role", response.data.role)
          setProfileData(response.data)
        }
        setNameInfo(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("@storage_Object")))
    const getData = () => {
      try {
        console.log("effect check")
        const value = localStorage.getItem("@storage_Key")
        const user_value = JSON.parse(localStorage.getItem("@storage_Object"))
        const user_role = localStorage.getItem("@storage_Role")
        console.log(`the value of token is ${user_value}`)
        if (
          value === null ||
          value === undefined ||
          value === "" ||
          user_value === null ||
          user_value === "" ||
          user_value === undefined
        ) {
          // console.log("Token: " + value)
          // console.log("User Object: " + JSON.parse(user_value).id)
          setToken("")
          setUser({})
          navigation("/sign-in")
        } else {
          setToken(value)
          setUser(user_value)

          console.log("i hit ", user_value)
          setGlobalRole(user_role)
        }
      } catch (e) {}
    }
    getData()
  }, [])

  console.log(user)

  useEffect(() => {
    if(user.id === undefined || user.id === null) {
      return
    }
    getCampaign()
  }, [user.id])

  useEffect(() => {
    getTestimonials()
  }, [singleCampaign])

  const [vidObj, setVidObj] = useState({
    video: "",
    VidName: "",
    description: "",
    inactive: "Active"
  })

  const getCampaign = () => {
    setContentLoading(true)
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/campaign/api/campaign/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(async(resp) => {
        console.log(resp.data.results)
        setCampaigns(resp.data.results)

        let filteredTests = resp.data.results.filter(result => {
          return (
            result.email ===
            JSON.parse(localStorage.getItem("@storage_Object")).email
          )
        })

        // setCampLength(filteredTests)

        var staffConfig = {
          method:'get',
          url:`https://ready-shout-30574.botics.co/staffMembers/userStaff/${user.id}/`,
          headers: {
            "content-type":"application/json",
            Authorization:`Bearer ${token}`
          }
        }

        filteredTests = filteredTests.map((firstEach)=> {
          return {...firstEach, creatorName:user.username}
        })

        let newResp = await axios(staffConfig)

        let newArray = []

        newResp.data.forEach((each,index)=> {
          let modifier = each.staffID.campaigns.map((newEach)=> {
            return {...newEach, creatorName: each.staffID.name}
          })
          newArray = newArray.concat(modifier)
        })

        filteredTests = filteredTests.concat(newArray)
        setCampLength(filteredTests)
        setContentLoading(false)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured, check your internet connection")
        setContentLoading(false)
      })
  }

  const textClipper = (txt, length) => {
    if (txt.length >= length) {
      let vb = ""
      for (var i = 0; i < length; i++) {
        vb += txt.charAt(i)
      }
      txt = `${vb}...`

      return txt
    }
    return txt
  }

  console.log(`the length of campaign is ${campLength.length}`)

  const getTestimonials = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/testimonials/api/testimonials/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)

        let filteredTests = resp.data.results.filter(result => {
          return result.campaignID === singleCampaign.key
        })
        setTestimonals(filteredTests)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured, check your internet connection")
      })
  }

  const patchCampaigns = () => {
    let newJSON = JSON.stringify(singleCampaign)
    console.log(newJSON)
    console.log(localStorage.getItem("@storage_Key"))
    var secondConfig = {
      method: "PATCH",
      url: `https://ready-shout-30574.botics.co/campaign/api/campaign/${singleCampaign.key}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },

      data: newJSON
    }
    axios(secondConfig)
      .then(response => {
        console.log(response.data)
        setUpdateCampaignModal(true)
      })
      .catch(err => {
        console.log(err)
        setUpdateCampaignFailModal(true)
      })
  }

  const deleteCampaigns = () => {
    console.log(localStorage.getItem("@storage_Key"))
    var secondConfig = {
      method: "DELETE",
      url: `https://ready-shout-30574.botics.co/campaign/api/campaign/${singleCampaign.key}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }
    axios(secondConfig)
      .then(response => {
        console.log(response.data)
        alert("Campaign Deleted")
        window.location.reload(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const Txts = e => {
    console.log(e.target.value)
    setFilterTxt(e.target.value)
  }

  const handleChange = files => {
    console.log(files[0])

    if (files[0] === undefined) {
      return
    }
    let obj = {
      uri: files[0].path,
      type: files[0].type,
      name: files[0].name
    }

    const data = new FormData()

    data.append("document", files[0])

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/uploader/api/uploader/",
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: data
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.document.split("?")[0])
        setVid(resp.data.document.split("?")[0])
        setSingleCampaign({
          ...singleCampaign,
          video: resp.data.document.split("?")[0]
        })
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = campLength.slice(indexOfFirstPost, indexOfLastPost)

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Header loc={"campaign"} campTxt={Txts} />

        {edit ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }}
          >
            <div className="top-titles">
              <p
                style={{
                  width: "90%",
                  marginTop: "40px",
                  marginBottom: "50px",
                  color: "white",
                  fontSize: "26px",
                  fontWeight: "bold",
                  fontFamily: "Work Sans"
                }}
              >
                View/Edit Campaign
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "210px",
                  height: "40px",
                  background: "#57b100",
                  color: "white",
                  borderRadius: "5px"
                }}
              >
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigation("/request-testimonial", {
                      state: { ...singleCampaign }
                    })
                  }
                >
                  Request Testimonial
                </p>
              </div>
            </div>

            <div className="main-edit-camp">
              <div className="main-edit-camp-right">
                <div>
                  <p>Name of the Campaign</p>
                  <input
                    type="text"
                    placeholder="Campaign Name"
                    className="input-sign"
                    defaultValue={singleCampaign.VidName}
                    onChange={e =>
                      setSingleCampaign({
                        ...singleCampaign,
                        VidName: e.target.value
                      })
                    }
                  />
                </div>

                <div>
                  <p>Description</p>
                  <textarea
                    type="text"
                    placeholder="Description"
                    style={{ minHeight: "200px" }}
                    className="input-sign"
                    defaultValue={singleCampaign.description}
                    onChange={e =>
                      setSingleCampaign({
                        ...singleCampaign,
                        description: e.target.value
                      })
                    }
                  />
                </div>

                <div className="boths">
                  <input
                    type="checkbox"
                    name="vehicle1"
                    value={
                      singleCampaign.inactive === "Inactive" ? true : false
                    }
                    onChange={e => {
                      if (e.target.value === "on") {
                        setSingleCampaign({
                          ...singleCampaign,
                          inactive: "Inactive"
                        })
                      } else {
                        setSingleCampaign({
                          ...singleCampaign,
                          inactive: "Active"
                        })
                      }
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Mark as inactive</p>
                </div>
                <div
                  className="boths"
                  style={{ marginLeft: "4px" }}
                  onClick={() => deleteCampaigns()}
                >
                  <img src={Bin} style={{ width: "13px", height: "13px" }} />
                  <p style={{ marginLeft: "10px" }}>Delete Campaign</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "210px",
                    height: "40px",
                    background: "#57b100",
                    color: "white",
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    patchCampaigns()
                  }}
                >
                  <p>Save</p>
                </div>
              </div>

              <div className="tests">
                {testimonals.length > 0 ? (
                  testimonals.map(test => {
                    // if(test.video === singleCampaign.)
                    if (
                      test.status === undefined ||
                      test.status === false ||
                      test.status === null
                    ) {
                      return (
                        <div className="each-test">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center"
                            }}
                          >
                            <img
                              src={JustImg}
                              style={{ height: "40px", width: "40px" }}
                            />
                            <p
                              style={{
                                marginLeft: "20px",
                                color: "white"
                              }}
                            >
                              {test.CustomerName}
                            </p>
                          </div>
                          <p
                            style={{
                              marginLeft: "0px",
                              color: "white"
                            }}
                          >
                            {test.createdAt || "-"}
                          </p>
                          <p
                            style={{
                              marginLeft: "0px",
                              color: "white",
                              fontSize: "14px"
                            }}
                          >
                            Testimonial Requested
                          </p>
                        </div>
                      )
                    } else
                      return (
                        <div className="each-test">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center"
                            }}
                          >
                            <img
                              src={JustImg}
                              style={{ height: "40px", width: "40px" }}
                            />
                            <p
                              style={{
                                marginLeft: "15px",
                                color: "white",
                                minWidth: "100px"
                              }}
                            >
                              {test.CustomerName}
                            </p>
                          </div>
                          <p style={{ color: "#57B100", marginLeft: "15px" }}>
                            {test.createdAt || "-"}
                          </p>
                          {/* <p
                        onClick={() => window.open(test.video)}
                        style={{
                          marginLeft: "80px",
                          color: "#57b100",
                          cursor: "pointer"
                        }}
                      >
                        {textClipper(test.video, 40)}
                      </p> */}

                          <button
                            className="login-btn-small"
                            style={{
                              cursor: "pointer",
                              marginLeft: "20px",
                              width: "100px"
                            }}
                            onClick={() => window.open(test.video)}
                          >
                            View Video
                          </button>
                        </div>
                      )
                  })
                ) : (
                  <p style={{ color: "white" }}>No testimonials Received</p>
                )}
              </div>
              {/* <div className="main-edit-camp-left">
                {editState ? (
                  <div className="cam-dropzone">
                    {vid === "" ? (
                      <DropzoneArea
                        onChange={handleChange}
                        dropzoneText="Upload Video"
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        previewText="Selected Video"
                        filesLimit={1}
                        maxFileSize={100000000}
                      />
                    ) : (
                      <embed
                        type="video/webm"
                        src={vid}
                        width="400"
                        height="400"
                      />
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "20px"
                    }}
                  >
                    <ReactFlowPlayer
                      playerInitScript="https://releases.flowplayer.org/7.2.1/flowplayer.min.js"
                      playerId="reactFlowPlayer"
                      className="FlowPlayer"
                      sources={[
                        {
                          type: "video/mp4",
                          src: singleCampaign.video
                        }
                      ]}
                    />
                  </div>
                )}

                <div className="main-edit-camp-left-bottom">
                  <div className="edit-one">
                    <p style={{ fontSize: "24px" }}>{singleCampaign.VidName}</p>
                    <p>Share to</p>
                  </div>

                  <div className="edit-two">
                    <p>{singleCampaign.description}</p>
                    <div className="edit-two-inner">
                      <img src={Fblogo} />
                      <img src={Twitterlogo} />
                    </div>
                  </div>
                  {editState ? null : (
                    <div
                      onClick={() => setEditState(true)}
                      className="last-camp"
                      style={{
                        marginBottom: 20,
                        alignSelf: "flex-start",
                        marginLeft: 35
                      }}
                    >
                      <p style={{ fontSize: "14px" }}>View/Edit</p>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
            {/*Continued here */}
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%"
              }}
            >
              <p
                style={{
                  width: "90%",
                  marginTop: "40px",
                  color: "white",
                  fontSize: "26px",
                  fontWeight: "bold",
                  fontFamily: "Work Sans"
                }}
              >
                Campaigns
              </p>
              {campLength.length <= 0 && contentLoading ? (
                <CircularProgress color="white" style={{ color: "white" }} />
              ) : campLength.length <= 0 ? (
                <p style={{ color: "white" }}>No Campaigns Created</p>
              ) : (
                <table className="coupon-table">
                  <tr>
                    <th style={{ paddingLeft: "20px" }}>Campaign Name</th>
                    <th>Created by</th>
                    <th>Created on</th>
                    <th style={{ textAlign: "center" }}>Actions</th>
                  </tr>
                  {campLength.length > 0 &&
                    currentPosts
                      .filter(camp =>
                        camp.VidName.toLowerCase().includes(
                          filterTxt.toLowerCase()
                        )
                      )
                      .map((camps, index) => {
                        return (
                          <tr
                            style={{
                              background: "#2D2C2C",
                              height: "80px",
                              borderBottom: "1px solid gray"
                            }}
                          >
                            <td style={{ paddingLeft: "20px" }}>
                              {camps.VidName}
                            </td>

                            {/* <td>
                              {profileData.FName !== "string" ||
                              profileData.FName !== ""
                                ? profileData.FName
                                : user.name === "string" || user.name === ""
                                ? "This User"
                                : user.name}
                            </td> */}
                            <td>
                              {camps?.creatorName || "-"}
                            </td>
                            <td>
                              {moment(camps?.created_at.split("T")[0]).format(
                                "MM-DD-YYYY"
                              )}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80px"
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: "#57B100",
                                  height: 40,
                                  width: 120,
                                  borderRadius: 10,
                                  alignSelf: "center",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  setEdit(true)
                                  setSingleCampaign(camps)
                                }}
                              >
                                <p style={{ color: "white" }}>Edit/View</p>
                              </div>
                            </td>

                            {/* <td>
                            <div
                              className="shared-btn"
                              onClick={() => {
                                setOpenShareModal(true)
                                setCurrentCoupon(coupon)
                              }}
                            >
                              <p>Share</p>
                            </div>
                          </td> */}
                          </tr>
                        )
                      })}
                </table>
              )}

              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={campLength.length}
                paginate={paginate}
              />
            </div>
            {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }}
          >
            <p
              style={{
                width: "90%",
                marginTop: "40px",
                color: "white",
                fontSize: "26px",
                fontWeight: "bold",
                fontFamily: "Work Sans"
              }}
            >
              Campaigns
            </p>
            <div className="cam-top">
              {campLength.length === 0 ? (
                <p style={{ color: "white", textAlign: "center" }}>
                  You don't have any campaigns yet.
                </p>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "wrap"
                  }}
                >
                  {campaigns
                    .filter(camp =>
                      camp.VidName.toLowerCase().includes(
                        filterTxt.toLowerCase()
                      )
                    )
                    .map(item => {
                      if (
                        item.email ===
                        JSON.parse(localStorage.getItem("@storage_Object"))
                          .email
                      ) {
                        return (
                          <div className="each-cam">
                            {item.video === "string" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center"
                                }}
                              >
                                <p style={{ color: "white" }}>No Video Found</p>
                              </div>
                            ) : (
                              <embed
                                src={item.video}
                                style={{
                                  borderTopRightRadius: "10px",
                                  borderTopLeftRadius: "10px"
                                }}
                              />
                            )}

                            <div className="each-cam-bottom">
                              <div className="each-cam-bottom-inner">
                                <p
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  {item.VidName}
                                </p>
                                <p style={{ color: "gray" }}>
                                  {item.description}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",

                                      width: "40%",
                                      justifyContent: "space-between",
                                      alignItems: "center"
                                    }}
                                  >
                                    <p style={{ color: "white" }}>Share to:</p>
                                    <FacebookShareButton
                                      url={item.video}
                                      quote="Check out my campaign"
                                      hashtag="#ready-shout"
                                    >
                                      <FacebookIcon
                                        size={20}
                                        style={{ borderRadius: "50px" }}
                                      />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                      url={item.video}
                                      title="Check out this campaign"
                                    >
                                      <TwitterIcon
                                        size={20}
                                        style={{ borderRadius: "50px" }}
                                      />
                                    </TwitterShareButton>
                                  </div>

                                  <div
                                    className="last-camp"
                                    onClick={() => {
                                      setEdit(true)
                                      setSingleCampaign(item)
                                    }}
                                  >
                                    <p style={{ fontSize: "14px" }}>
                                      View/Edit
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })}
                </div>
              )}
            </div>
          </div> */}
          </>
        )}

        {updateCampaignModal && (
          <PopUp
            open={updateCampaignModal}
            handleClose={() => setUpdateCampaignModal(false)}
            title={"Success"}
            text={"Campaign Updated Successfully"}
            okText={"ok"}
            okAction={() => setUpdateCampaignModal(false)}
          />
        )}
        {updateCampaignFailModal && (
          <PopUp
            open={updateCampaignFailModal}
            handleClose={() => setUpdateCampaignFailModal(false)}
            title={"Failed"}
            text={"Something went wrong please try again later"}
            okText={"ok"}
            okAction={() => setUpdateCampaignFailModal(false)}
          />
        )}
      </div>
    </>
  )
}

export default Campaigns
