import React, { useState, useEffect, useContext, useRef } from "react"

import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"

import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { TokenContext } from "../../context/TokenContext"

const AddCoupon = () => {
  const navigation = useNavigate()
  const [state, setState] = useState("")
 
  const { token, setToken, user, setUser } = useContext(TokenContext)
 
  const [couponObj, setCouponObj] = useState({
    couponName: "",
    active: true,
    startDate: "",
    endDate: "",
    pic: "",
    usedID:user.id,
    description:"",
    business:""
  })
  let imageRef = useRef()

  const [flip1, setFlip1] = useState(false)
  const [flip2, setFlip2] = useState(false)
  const [imageFile, setImageFile] = useState(null)
  const [loading, setLoading] = useState(false)
  console.log('here user is', user)

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      navigation("/sign-in")
    }
  }, [])

  const addCoupon = () => {
    if (
      couponObj.couponName === "" ||
      couponObj.startDate === "" ||
      couponObj.endDate === "" ||
      couponObj.description === "" ||
      couponObj.business === ""
    ) {
      alert("Please fill all the details")
      return
    }

    if(couponObj.pic === "") {
      alert("Please upload a coupon picture")
      return
    }

    setLoading(true)

    console.log('the coupon obj is', couponObj)

    let JSONed = JSON.stringify(couponObj)
    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/coupons/api/Coupon/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        alert("Coupon Added Successfully")
        setLoading(false)
        window.location.reload(false)
      })
      .catch(err => {
        console.log(err.message)
        setLoading(false)
        alert("An error occured")
      })
  }

  const uploaders = e => {
    let data = new FormData()

    console.log("hehe")

    data.append("document", e.target.files[0])

    console.log(data)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/uploader/api/uploader/",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: data
    }

    axios(config)
      .then(response => {
        console.log(response.data.document)
        setCouponObj({ ...couponObj, pic: response.data.document })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <p
            style={{
              width: "90%",
              marginTop: "40px",
              color: "white",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Work Sans",
              cursor: "pointer"
            }}
            onClick={() => navigation("/coupons")}
          >
            {"< Add a Coupon"}
          </p>
          <div className="settings-main-short">
            <p style={{ color: "white", fontSize: "18px", textAlign: "left" }}>
              Enter Coupon Details
            </p>

            {/* <input
              type="text"
              placeholder="Select a Campaign"
              className="input-sign-new"
              style={{ marginTop: "40px" }}
            /> */}
            <input
              type="text"
              placeholder="Name of the Coupon"
              className="input-sign-new"
              onChange={e =>
                setCouponObj({ ...couponObj, couponName: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Business Name"
              className="input-sign-new"
              onChange={e =>
                setCouponObj({ ...couponObj, business: e.target.value })
              }
            />
            {flip1 ? (
              <input
                type="date"
                placeholder="Start Date"
                className="input-sign-new"
                onChange={e =>
                  setCouponObj({ ...couponObj, startDate: e.target.value })
                }
              />
            ) : (
              <input
                type="text"
                placeholder="Start Date"
                className="input-sign-new"
                onFocus={() => setFlip1(true)}
                //   onChange={e => setEmail(e.target.value)}
              />
            )}

            {flip2 ? (
              <input
                type="date"
                placeholder="Start Date"
                className="input-sign-new"
                onChange={e =>
                  setCouponObj({ ...couponObj, endDate: e.target.value })
                }
              />
            ) : (
              <input
                type="text"
                placeholder="End Date"
                className="input-sign-new"
                onFocus={() => setFlip2(true)}
                //   onChange={e => setEmail(e.target.value)}
              />
            )}

            <textarea
              className="input-sign-new"
              style={{ minHeight: "150px" }}
              type="text"
              placeholder="Description"
              onChange={e =>
                setCouponObj({ ...couponObj, description: e.target.value })
              }
            />

            <input
              type="file"
              name="myImage"
              accept="image/png, image/gif, image/jpeg"
              style={{ display: "none" }}
              ref={imageRef}
              onChange={e => uploaders(e)}
            />

            <button
              className="login-btn-small"
              style={{ cursor: "pointer" }}
              onClick={() => imageRef.current.click()}
            >
              + Upload Coupon Image
            </button>
            <button
              className="login-btn-small"
              style={{ cursor: "pointer" }}
              onClick={addCoupon}
            >
              {
                loading ? "Loading ...":"Add Coupon"
              }
              
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddCoupon
