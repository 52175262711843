import React, { useContext, useState, useEffect } from "react"
import RShoutImg from "./../../assets/images/readyshout.png"
import SearchImg from "./../../assets/images/search.png"
import BellImg from "./../../assets/images/bell.png"
import ProfileImg from "./../../assets/images/profile.png"
import "./Header.css"
import { useNavigate } from "react-router-dom"
import { NotificationContext } from "../../context/NotificationContext"
import { TokenContext } from "../../context/TokenContext"
import axios from "axios"

const Header = props => {
  const navigation = useNavigate()

  const { fromHeader, setFromHeader } = useContext(NotificationContext)
  const { nameInfo, setNameInfo, color, setColor, globalRole, setGlobalRole } =
    useContext(TokenContext)
  const [notificationList, setNotificationList] = useState([])

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    const user_role = localStorage.getItem("@storage_Role")
    setGlobalRole(user_role)
  }, [])

  const getNotifications = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/notifications/api/notifications/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)
        setNotificationList(resp.data.results)

        let filters = resp.data.results.filter(res => res.inChecked === false)
        setNotificationList(filters)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }

  console.log(color)
  console.log(nameInfo.FName.charAt(0))
  console.log(globalRole)

  return (
    <div className="header-main">
      <img src={RShoutImg} />
      <div className="header-input-div">
        <img src={SearchImg} />
        {props?.loc === "campaign" ? (
          <input
            type="text"
            className="header-inp"
            onChange={props?.campTxt}
            placeholder="Enter Campaign Name"
          />
        ) : props?.loc === "testimonial" ? (
          <input
            type="text"
            className="header-inp"
            onChange={props?.testTxt}
            placeholder="Enter Company Name"
          />
        ) : (
          <input type="text" className="header-inp" />
        )}
      </div>
      <div
        className="create-div"
        onClick={() => navigation("/create-campaign")}
      >
        <button className="create-btn">+</button>
        <p>Create a Campaign</p>
      </div>
      <div className="header-last">
        <img
          style={{ cursor: "pointer" }}
          src={BellImg}
          onClick={() => {
            setFromHeader(true)
            navigation("/settings")
          }}
        />
        <p style={{ color: "white", marginLeft: "-20px" }}>
          {notificationList.length === 0 ? "" : notificationList.length}
        </p>

        {/* <img src={ProfileImg} style={{ marginTop: "-3px" }} /> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            background: color,
            cursor: "pointer"
          }}
          onClick={() => navigation("/my-profile")}
        >
          <p
            style={{
              color: "white",
              fontWeight: 600,
              textTransform: "capitalize"
            }}
          >
            {nameInfo.FName.charAt(0) || "U"}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Header
