import React, { useState, useEffect, useContext } from "react"
import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"
import "./TokenInput.css"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { ResetTokenContext } from "../../context/ResetTokenContext"

const TokenInput = () => {
  const navigation = useNavigate()

  const [tokenDigits, setTokenDigits] = useState({
    t1: "",
    t2: "",
    t3: "",
    t4: "",
    t5: "",
    t6: ""
  })

  const [finalToken, setFinalToken] = useState("")
  const { resToken, setResToken } = useContext(ResetTokenContext)

  useEffect(() => {
    setFinalToken(
      `${tokenDigits.t1}${tokenDigits.t2}${tokenDigits.t3}${tokenDigits.t4}${tokenDigits.t5}${tokenDigits.t6}`
    )
  }, [tokenDigits])

  console.log(finalToken)

  const verifyToken = () => {
    let data = {
      token: finalToken
    }

    let JSONed = JSON.stringify(data)
    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/password/reset/verify-token/",
      headers: {
        "content-type": "application/json"
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        setResToken(data.token)
        alert("Token Verified")
        navigation("/update-password")
      })
      .catch(err => {
        console.log(err)
        alert("Token is either invalid or expired")
      })
  }

  return (
    <div className="main-landing">
      <img src={rectangle} className="abs-rect" />

      <div className="landing-middle-left">
        <img src={logo} className="sign-logo" />

        <div className="sign-middle">
          <div className="sign-middle-left">
            <p
              style={{
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: "bold",
                fontSize: 24
              }}
            >
              Token Input
            </p>
            <p style={{ color: "white", marginBottom: "35px" }}>
              Please Enter the token number received on your registered email
            </p>
            <div>
              <input
                type="text"
                className="token-inps"
                maxLength={1}
                onChange={e =>
                  setTokenDigits({ ...tokenDigits, t1: e.target.value })
                }
              ></input>
              <input
                type="text"
                className="token-inps"
                maxLength={1}
                onChange={e =>
                  setTokenDigits({ ...tokenDigits, t2: e.target.value })
                }
              ></input>
              <input
                type="text"
                className="token-inps"
                maxLength={1}
                onChange={e =>
                  setTokenDigits({ ...tokenDigits, t3: e.target.value })
                }
              ></input>
              <input
                type="text"
                className="token-inps"
                maxLength={1}
                onChange={e =>
                  setTokenDigits({ ...tokenDigits, t4: e.target.value })
                }
              ></input>
              <input
                type="text"
                className="token-inps"
                maxLength={1}
                onChange={e =>
                  setTokenDigits({ ...tokenDigits, t5: e.target.value })
                }
              ></input>
              <input
                type="text"
                className="token-inps"
                maxLength={1}
                onChange={e =>
                  setTokenDigits({ ...tokenDigits, t6: e.target.value })
                }
              ></input>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: "20px"
              }}
            >
              <p style={{ marginLeft: "7px", color: "white" }}>
                Didn't receive token ?
              </p>
              <p
                style={{ color: "#57b100", fontWeight: "bold" }}
                onClick={() => {
                  navigation("/password-reset")
                }}
              >
                Resend here
              </p>
            </div>

            <button
              className="login-btn"
              style={{ cursor: "pointer" }}
              onClick={verifyToken}
            >
              Submit
            </button>
            <p
              style={{
                textAlign: "center",
                color: "#57b100",
                fontWeight: "bold"
              }}
              onClick={() => {
                navigation("/password-reset")
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      </div>

      <img src={laptop} className="landing-middle-right" />
    </div>
  )
}

export default TokenInput
