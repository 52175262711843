import React, { useState, useEffect, useContext } from "react"
import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"
import "./UpdatePassword.css"
import { useNavigate } from "react-router-dom"
import { ResetTokenContext } from "../../context/ResetTokenContext"
import axios from "axios"

const UpdatePassword = () => {
  const navigation = useNavigate()

  const { resToken, setResToken } = useContext(ResetTokenContext)
  const [updateObj, setUpdateObj] = useState({
    password: "",
    token: "",
    password2: ""
  })

  useEffect(() => {
    setUpdateObj({ ...updateObj, token: resToken })
  }, [resToken])

  const update = () => {
    if (updateObj.password !== updateObj.password2) {
      alert("Passwords do not match")
      return
    }

    let JSONed = JSON.stringify(updateObj)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/password/reset/confirm/",
      headers: {
        "content-type": "application/json"
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        alert("Password Reset successfully")
        navigation("/sign-in")
      })
      .catch(err => {
        console.log(err)
        alert("Failed")
      })
  }

  return (
    <div className="main-landing">
      <img src={rectangle} className="abs-rect" />

      <div className="landing-middle-left">
        <img src={logo} className="sign-logo" />

        <div className="sign-middle">
          <div className="sign-middle-left">
            <p
              style={{
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: "bold",
                fontSize: 24
              }}
            >
              Update Password
            </p>
            <p style={{ color: "white" }}>Please update your password</p>
            <div>
              <input
                type="text"
                placeholder="New Password"
                className="input-sign"
                onChange={e =>
                  setUpdateObj({ ...updateObj, password: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Update New Password"
                className="input-sign"
                onChange={e =>
                  setUpdateObj({ ...updateObj, password2: e.target.value })
                }
              />
            </div>

            <button className="login-btn" onClick={update}>
              Update
            </button>
            <p
              style={{
                textAlign: "center",
                color: "#57b100",
                fontWeight: "bold"
              }}
              onClick={() => {
                navigation("/sign-in")
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      </div>

      <img src={laptop} className="landing-middle-right" />
    </div>
  )
}

export default UpdatePassword
