import React, { useState, useEffect, useContext } from "react"
import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Form } from "react-bootstrap"
import "./AddStaff.css"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"
import { TokenContext } from "../../context/TokenContext"

const AddStaff = () => {
  const navigation = useNavigate()
  const [state, setState] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [campaigns, setCampaigns] = useState([])
  const [loading, setLoading] = useState(false)

  const roles = ["Manager", "User", "Admin"]

  const [details, setDetails] = useState({
    name: "user",
    email: "",
    password: "ieuhrwe98ghern9cnqew8H34W8WG",
    companyName: "",
    phone: "",
    external_id: "",
    role: "",
    FName: "",
    LName: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    PrimaryAddress: "",
    SecondaryAddress: ""
  })

  const { user, setUser, profileData, setProfileData } =
    useContext(TokenContext)

  console.log("the user is", profileData)

  console.log(details)

  const handleInvite = () => {
    if (details.name === "" || details.email === "" || details.phone === "") {
      alert("Please fill all the required information")
      return
    }

    if (!details.email.includes("@")) {
      alert("Invalid Email format")
      return
    }

    setLoading(true)

    let SignObj = {
      name: details.name,
      password: details.password,
      email: details.email
    }

    let JSONed = JSON.stringify(SignObj)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/signup/",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed
    }

    var summonedID = null

    axios(config)
      .then(response => {
        console.log(response.data)
        summonedID = response.data.user.id

        // localStorage.setItem("@storage_Key", response.data.access_token)
        // localStorage.setItem(
        //   "@storage_Object",
        //   JSON.stringify(response.data.user)
        // )

        let newData = {
          external_id: response.data.user.external_id,
          role: details.role,
          name: details.name,
          CompanyName: profileData.CompanyName,
          phone: details.phone
        }

        let bearToken = response.data.access_token

        console.log(response.data.access_token)
        let newJSON = JSON.stringify(newData)

        var secondConfig = {
          method: "PATCH",
          url: "https://ready-shout-30574.botics.co/users/api/profile/",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.data.access_token}`
          },

          data: newJSON
        }

        axios(secondConfig)
          .then(newresponse => {
            console.log(newresponse.data)

            let thirdJSON = JSON.stringify({
              userID: user.id,
              staffID: summonedID
            })

            var thirdConfig = {
              method: "POST",
              url: "https://ready-shout-30574.botics.co/staffMembers/api/staffMembers/",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${bearToken}`
              },

              data: thirdJSON
            }

            axios(thirdConfig)
              .then(resp => {

                console.log(resp.data)

                let lastData = {
                  email: details.email,
                  message: "You have been invited to readyshout",
                  password: SignObj.password
                }

                let finalJSON = JSON.stringify(lastData)

                var finalConfig = {
                  method: "POST",
                  url: `https://ready-shout-30574.botics.co/users/api/sendInvite/`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${bearToken}`
                  },

                  data: finalJSON
                }

                axios(finalConfig)
                  .then(response => {
                    alert("Invite sent successfully")
                    setLoading(false)
                  })
                  .catch(err => {
                    alert("An error occured")
                    setLoading(false)
                  })
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
            alert("Signup Failed")
            setLoading(false)
          })
      })
      .catch(err => {
        console.log(err)
        if (
          err?.response?.data?.email !== undefined ||
          err?.response?.data?.email.length > 0
        ) {
          alert(err?.response?.data?.email[0])
        } else {
          alert("Signup Failed, please check your internet connection")
        }
        setLoading(false)
      })
  }

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <p
            style={{
              width: "90%",
              marginTop: "40px",
              color: "white",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Work Sans",
              cursor: "pointer"
            }}
            onClick={() => navigation("/staff-members")}
          >
            {"< Add Staff"}
          </p>
          <div className="settings-main-short">
            <p style={{ color: "white", fontSize: "18px", textAlign: "left" }}>
              Enter Staff Member Details
            </p>

            {/* <input
              type="text"
              placeholder="Select a Campaign"
              className="input-sign-new"
              style={{ marginTop: "40px" }}
            /> */}
            <input
              type="text"
              placeholder="Staff Members Name"
              className="input-sign-new"
              onChange={e => setDetails({ ...details, name: e.target.value })}
            />
            <input
              type="text"
              placeholder="Staff Members Email Address"
              className="input-sign-new"
              onChange={e => setDetails({ ...details, email: e.target.value })}
            />
            <input
              type="text"
              placeholder="Staff Members Phone Number"
              className="input-sign-new"
              onChange={e => setDetails({ ...details, phone: e.target.value })}
            />

            <select
              id="campaigns"
              name="Campaigns"
              onChange={e => setDetails({ ...details, role: e.target.value })}
              style={{ width: "62.5%" }}
              className="input-sign-new"
            >
              <option value="">Staff Member Access type</option>
              {roles.map((role, id) => (
                <option key={id} value={role}>
                  {role}
                </option>
              ))}
            </select>

            {loading ? (
              <CircularProgress />
            ) : (
              <button className="login-btn-small" onClick={handleInvite}>
                Invite Staff Member
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddStaff
