import React, { useState, useEffect, useContext } from "react"
import "./Signup.css"
import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import { Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { TokenContext } from "../../context/TokenContext"

const Signup = () => {
  const navigation = useNavigate()

  const [details, setDetails] = useState({
    name: "user",
    email: "",
    password: "",
    companyName: "",
    phone: "",
    external_id: "",
    role: "",
    FName: "",
    LName: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    PrimaryAddress: "",
    SecondaryAddress: ""
  })

  const { eid, setEid } = useContext(TokenContext)

  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")

  console.log(password)
  console.log(password2)

  const handleSignUp = e => {
    e.preventDefault()

    if (password !== password2) {
      alert("Passwords do not match")
      return
    }

    if (password.length < 8) {
      alert("Password must be atleast 8 characters long")
      return
    }

    var letters = /^[A-Za-z]\w{7,14}$/
    var newLetters = /^(?=.*[0-9])(?=.*[!@#$%^_&|*])[a-zA-Z0-9!@#$%^_&*]{7,15}$/

    if (!password.match(newLetters)) {
      alert(
        "password must be between 8 to 15 characters which contain at least one numeric digit and a special character"
      )
      return
    }

    if (
      details.companyName === "" ||
      details.email === "" ||
      details.phone === ""
    ) {
      alert("Please fill all the required information")
      return
    }

    if (!details.email.includes("@")) {
      alert("Invalid Email format")
      return
    }

    let checkedPassword = password

    setDetails({ ...details, password: checkedPassword })

    let SignObj = {
      name: details.name,
      password: checkedPassword,
      email: details.email
    }

    let JSONed = JSON.stringify(SignObj)

    var config = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/users/api/signup/",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSONed
    }

    axios(config)
      .then(response => {
        console.log(response.data)

        // localStorage.setItem("@storage_Key", response.data.access_token)
        // localStorage.setItem(
        //   "@storage_Object",
        //   JSON.stringify(response.data.user)
        // )

        let newData = {
          external_id: response.data.user.external_id,
          role: "user",
          name: "string",
          FName: "string",
          LName: "string",
          CompanyName: details.companyName,
          phone: details.phone,
          country: "string",
          city: "string",
          state: "string",
          zip: "string",
          PrimaryAddress: "string",
          SecondaryAddress: "string"
        }

        console.log(response.data.access_token)
        let newJSON = JSON.stringify(newData)

        localStorage.setItem("@storage_Key", response.data.access_token)
        localStorage.setItem(
          "@storage_Object",
          JSON.stringify(response.data.user)
        )

        var secondConfig = {
          method: "PATCH",
          url: "https://ready-shout-30574.botics.co/users/api/profile/",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.data.access_token}`
          },

          data: newJSON
        }

        axios(secondConfig)
          .then(response => {
            console.log(response.data)
            // alert("Registration Successfull, Please login to continue")
            navigation("/campaigns")
          })
          .catch(err => {
            console.log(err)
            alert("Signup Failed")
          })
      })
      .catch(err => {
        console.log(err)
        if (
          err?.response?.data?.email !== undefined ||
          err?.response?.data?.email.length > 0
        ) {
          alert(err?.response?.data?.email[0])
        } else {
          alert("Signup Failed, please check your internet connection")
        }
      })
  }

  console.log(details)

  return (
    <div className="main-landing-signup">
      <img src={rectangle} className="abs-rect" />

      <div className="landing-middle-left">
        <img src={logo}  style={{width:'300px', height:'70px',marginBottom:'10px'}} />

        <div className="sign-middle-new">
          <form className="sign-middle-left" onSubmit={handleSignUp}>
            <p
              style={{
                color: "white",
                fontFamily: "Work Sans",
                fontWeight: "bold",
                fontSize: 24
              }}
            >
              Sign up
            </p>
            <div>
              <input
                type="text"
                placeholder="Company Name"
                className="input-sign"
                onChange={e =>
                  setDetails({ ...details, companyName: e.target.value })
                }
                required
              />
              <input
                type="text"
                placeholder="Email Address"
                className="input-sign"
                onChange={e =>
                  setDetails({ ...details, email: e.target.value })
                }
                required
              />
              <input
                type="text"
                placeholder="Phone Number"
                className="input-sign"
                onChange={e =>
                  setDetails({ ...details, phone: e.target.value })
                }
                required
              />
              <input
                type="password"
                placeholder="Set Password"
                className="input-sign"
                onChange={e => setPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Confirm Password"
                className="input-sign"
                onChange={e => setPassword2(e.target.value)}
                required
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <Form.Check type="checkbox" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around"
                }}
              >
                <p style={{ marginLeft: "7px", color: "white" }}>I accept</p>
                <p
                  style={{
                    marginLeft: "7px",
                    color: "#57b100",
                    fontWeight: "bold"
                  }}
                >
                  Terms and conditions
                </p>
              </div>
            </div>

            <button
              className="login-btn"
              type="submit"
              style={{ cursor: "pointer" }}
            >
              Signup
            </button>
            

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <p style={{ marginLeft: "7px",fontSize:"14px", color: "white" }}>
                Already have an account ?
              </p>
              <p
                style={{
                  
                  color: "#57b100",
                  fontWeight: "bold",
                  fontSize:'14px',
                  marginLeft:'7px',
                  cursor:'pointer'
                }}
                onClick={() => navigation("/sign-in")}
              >
                Login here
              </p>
            </div>
          </form>
        </div>
      </div>

      <img src={laptop} className="landing-middle-right" />
    </div>
  )
}

export default Signup
