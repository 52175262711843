import React, { useState, useEffect, useContext } from "react"

import rectangle from "../../assets/images/rectangle.png"
import laptop from "../../assets/images/laptop.png"
import logo from "../../assets/images/logo.png"
import Flogo from "../../assets/images/fblogo.png"
import Glogo from "../../assets/images/googlelogo.png"
import { Alert, Form } from "react-bootstrap"
import "./RequestTestimonial.css"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import axios from "axios"
import { useNavigate, useLocation } from "react-router-dom"
import { TokenContext } from "../../context/TokenContext"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import SearchImg from "./../../assets/images/search.png"
import ProfileImg from "../../assets/images/usernew.png"

const RequestTestimonial = props => {
  let newState = useLocation()


  console.log("ns is",newState)

  const navigation = useNavigate()
  const [olState, setOlState] = useState("")
  const [state, setState] = useState("")
  const [campID, setCampID] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [campaigns, setCampaigns] = useState([])
  const [contacts, setContacts] = useState([])
  const [deadContacts, setDeadContacts] = useState([])
  const [transferArray, setTransferArray] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [contactTxt, setContactTxt] = useState("")
  const [campLength, setCampLength] = useState([])
  const [reqObj, setReqObj] = useState({})
  const [warningModal, setWarningModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [warningModal2, setWarningModal2] = useState(false)
  const [successModal2, setSuccessModal2] = useState(false)
  const [openContacts, setOpenContacts] = useState(false)
  const [loading, setLoading] = useState(false)
  const [things, setThings] = useState({
    PurposeOFProject:'',
    videoGiftReciever:'',
    ProjectOwnerName:''
  })
  const { token, setToken, user, setUser } = useContext(TokenContext)


  useEffect(()=> {
    setCampID(newState?.state?.key || "")
  },[newState])

  useEffect(() => {
    if (
      newState?.state?.VidName === undefined ||
      newState?.state?.VidName === null ||
      newState?.state?.VidName === ""
    ) {
      setState("")
    } else {
      setState(newState.state.VidName)
    }
  }, [])

  useEffect(() => {
    getCampaign()
  }, [])

  useEffect(() => {
    if (token === null || token === undefined || token === "") {
      navigation("/sign-in")
    }
  }, [])

  useEffect(() => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/contact/api/Contact/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {


        let filteredTests = resp.data.results.filter(result => {
          return (
            result.email ===
            JSON.parse(localStorage.getItem("@storage_Object")).email
          )
        })

        let tempData = filteredTests

        tempData.forEach((data, index) => {
          data.isChecked = false
        })

        tempData.forEach((data, index) => {
          data.id = index
        })
        setContacts(tempData)

        // const arr = Array(resp.data.results.length)
        // arr.fill(false)
        // setArrayOfBooleans(arr)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }, [])

  console.log("Contacts:", contacts)

  const getCampaign = () => {
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/campaign/api/campaign/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {

        setCampaigns(resp.data.results)

        let filteredTests = resp.data.results.filter(result => {
          return (
            result.email ===
            JSON.parse(localStorage.getItem("@storage_Object")).email
          )
        })

        setCampLength(filteredTests)
      })
      .catch(err => {
        console.log(err.message)
        alert("An error occured")
      })
  }

  const altPost = () => {
    let allObjs = [...transferArray]
    setLoading(true)

    let undeadEmails = allObjs.filter(
      objs =>
        objs.contactEmail !== "" ||
        objs.contactEmail !== null ||
        objs.contactEmail !== undefined
    )

    let secondWave = undeadEmails.filter(objs =>
      objs.contactEmail.includes("@")
    )

    var today = new Date()
    var dd = String(today.getDate()).padStart(2, "0")
    var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = today.getFullYear()

    today = mm + "/" + dd + "/" + yyyy

    secondWave.forEach(item=> {
      let datas = {
        status: false,
      video: "123",
      CompanyName: "123",
      CustomerName: item.contactName,
      CompanyEmail: item.contactEmail,
      CustomerPhone: "+923052371389",
      userID: user.id.toString(),
      campaignID: campID,
      createdAt:today
      }



      let JSONed = JSON.stringify(datas)

      var config = {
        method: "POST",
        url: "https://ready-shout-30574.botics.co/users/api/sendTestimonials/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
        },
        data: JSONed
      }

      axios(config)
        .then(response => {
          console.log(response.data)
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    })

    Alert("Emails sent successfully")
  }

  const altSMSPost = () => {
    let allObjs = [...transferArray]
    setLoading(true)

    let undeadEmails = allObjs.filter(
      objs =>
        objs.contactEmail !== "" ||
        objs.contactEmail !== null ||
        objs.contactEmail !== undefined
    )

    let secondWave = undeadEmails.filter(objs =>
      objs.contactEmail.includes("@")
    )

    let thirdWave = secondWave.filter(
      objs => objs.contactNumber === "" || objs.contactNumber === "email"
    )

    var today = new Date()
    var dd = String(today.getDate()).padStart(2, "0")
    var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = today.getFullYear()

    today = mm + "/" + dd + "/" + yyyy



    thirdWave.forEach(email => {

      let numFormat = email.contactNumber.toString()
      let txt1 = "+"
      let result = txt1.concat(numFormat)

      console.log('result is',result)



      let datas = {
        email: email.contactEmail,
        PurposeOFProject: things.PurposeOFProject,
        videoGiftReciever: things.videoGiftReciever,
        DateAndTime: today,
        ProjectOwnerName: things.ProjectOwnerName,
        phone:result,
        campaignID:email.key,
        userID: user.id.toString()
      }





      let JSONed = JSON.stringify(datas)

      var config = {
        method: "POST",
        url: "https://ready-shout-30574.botics.co/users/api/send_sms/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
        },
        data: JSONed
      }

      axios(config)
        .then(response => {
          console.log(response.data)
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          alert("Some error occured")
          setLoading(true)
        })
    })

    Alert("SMS sent successfully")
  }

  console.log("transfer ", transferArray)

  const postTestimonial = async () => {
    {
      /*Filter Invalid emails */
    }

    setOpenContacts(false)
    setDeadContacts([])
    setLoading(true)

    let allObjs = [...transferArray]

    let doubleFilter = allObjs.filter(
      objs => objs.contactEmail === "" || !objs.contactEmail.includes("@")
    )

    let deadEmails = allObjs.filter(
      objs =>
        objs.contactEmail === "" ||
        objs.contactEmail === null ||
        objs.contactEmail === undefined
    )

    let secondFilter = allObjs.filter(objs => !objs.contactEmail.includes("@"))

    setDeadContacts(doubleFilter)


    var today = new Date()
    var dd = String(today.getDate()).padStart(2, "0")
    var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = today.getFullYear()

    today = mm + "/" + dd + "/" + yyyy


   allObjs.forEach(item => {


    let JSONed2 = JSON.stringify({
      status: false,
      video: "123",
      CompanyName: "123",
      CustomerName: item.contactName,
      CompanyEmail: item.contactEmail,
      CustomerPhone: "+923052371389",
      usedID: 1,
      campaignID: campID,
      createdAt:today,
      business:things.ProjectOwnerName
    })

    console.log('josn2 is ',JSONed2)

    var config2 = {
      method: "POST",
      url: "https://ready-shout-30574.botics.co/testimonials/api/testimonials/",
      headers: {
        "Content-Type": "application/json",

      },
      data: JSONed2
    }

      axios(config2)
        .then(response => {
          console.log(response.data)

          let JSONed = JSON.stringify({
            email: item.contactEmail,
            PurposeOFProject: things.PurposeOFProject,
            VideoGiftReciever:things.videoGiftReciever,
            DateAndTime: today,
            ProjectOwnerName: things.ProjectOwnerName,
            ContactFirstName:item.contactName,
            campaignID:campID,
            testimonialID:response.data.key,
            business:things.ProjectOwnerName,
            userID:user.id.toString()
          })

          console.log('main JSONED is', JSONed)

          var config = {
            method: "POST",
            url: "https://ready-shout-30574.botics.co/users/api/sendTestimonials/",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            },
            data: JSONed
          }

          axios(config)
            .then((resp)=>{
              console.log(resp.data)

              setLoading(false)
              window.location.reload()

            })
            .catch((err)=>{

              setLoading(false)
              // window.location.reload()
              alert("Something went wrong", err)
            })


          setLoading(false)
        })
        .catch(err => {
          alert(err)
          setLoading(false)
          console.log('another wrong',err)
          // window.location.reload()
          return
        })
      }
    )
    alert("Success","Emails sent")
    // console.log("stringified:", stringified)

    // const newConfigs = stringified.map(strings => getConfig(strings))

    // const result = await Promise.allSettled([newConfigs])
    // console.log("result:", result)

    // if (result[0].status === "fulfilled") {
    //   alert("Emails send successfully")
    // } else {
    //   alert("Something went wrong")
    // }

    // axios(config)
    //   .then(response => {
    //     console.log(response.data)
    //   })
    //   .catch(err => {
    //     alert(err)
    //     return
    //   })

    // allObjs.forEach(obj => {
    //   let JSONed = JSON.stringify({
    //     email: obj.contactEmail,
    //     campaign: state,
    //     message: message
    //   })

    //   console.log(JSONed)

    //   var config = {
    //     method: "POST",
    //     url: "https://ready-shout-30574.botics.co/users/api/sendTestimonials/",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
    //     },
    //     data: JSONed
    //   }

    //   axios(config)
    //     .then(response => {
    //       console.log(response.data)
    //     })
    //     .catch(err => {
    //       alert(err)
    //       return
    //     })

    //   setSuccessModal(true)
    // })
  }

  console.log('current state is',state)

  const postSMS = () => {
    {
      /*Filter Invalid emails */
    }

    setOpenContacts(false)
    setDeadContacts([])
    setLoading(true)

    let allObjs = [...transferArray]

    let doubleFilter = allObjs.filter(
      objs => objs.contactNumber === "" || objs.contactNumber === "email"
    )

    let deadEmails = allObjs.filter(
      objs =>
        objs.contactNumber === "" ||
        objs.contactEmail === null ||
        objs.contactEmail === undefined
    )

    let secondFilter = allObjs.filter(objs => objs.contactNumber === "email")

    setDeadContacts(doubleFilter)

    if (deadEmails.length > 0 || secondFilter.length > 0) {
      setWarningModal(true)
    } else {

      var today = new Date()
      var dd = String(today.getDate()).padStart(2, "0")
      var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
      var yyyy = today.getFullYear()

      today = mm + "/" + dd + "/" + yyyy

      console.log('all objs are',allObjs)

      allObjs.forEach(obj => {

        let JSONed2 = JSON.stringify({
          status: false,
          video: "123",
          CompanyName: "123",
          CustomerName: obj.contactName,
          CompanyEmail: obj.contactEmail,
          CustomerPhone: "+923052371389",
          usedID: 1,
          campaignID: campID,
          createdAt:today,
          business:things.ProjectOwnerName
        })

        let numFormat = obj.contactNumber
        let txt1 = "+"
        let result = txt1.concat(numFormat)

        console.log('result is',result)

        var config2 = {
          method: "POST",
          url: "https://ready-shout-30574.botics.co/testimonials/api/testimonials/",
          headers: {
            "Content-Type": "application/json",

          },
          data: JSONed2
        }

        axios(config2)
          .then((response)=>{

            let JSONed = JSON.stringify({
              email: obj.contactEmail,
              phone: result,
              PurposeOFProject: things.PurposeOFProject,
              VideoGiftReciever:things.videoGiftReciever,
              DateAndTime: today,
              ProjectOwnerName: things.ProjectOwnerName,
              ContactFirstName:obj.contactName,
              campaignID:campID,
              testimonialID:response.data.key,
              business:things.ProjectOwnerName,
              userID: user.id.toString()
            })

            console.log('now JSONED is',JSONed)

            var config = {
              method: "POST",
              url: "https://ready-shout-30574.botics.co/users/api/send_sms/",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
              },
              data: JSONed
            }

            axios(config)
            .then(response => {
              console.log(response.data)
              alert("Success, SMS sent successfully")
              setLoading(false)
            })
            .catch(err => {
              alert(err)
              console.log(err)
              setLoading(false)
              setSuccessModal2(true)
              return
            })
          })
          .catch(err => {
            console.log(err)
          })

      })
    }

    // let JSONed2 = JSON.stringify({
    //   video: "sdgdfgfd",
    //   email: user.email,
    //   CompanyName: companyName,
    //   CustomerName: customerName,
    //   CustomerPhone: phone,
    //   CompanyEmail: email,
    //   usedID: user.id
    // })

    // console.log(JSONed2)

    // var config = {
    //   method: "POST",
    //   url: "https://ready-shout-30574.botics.co/users/api/sendTestimonials/",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
    //   },
    //   data: JSONed
    // }
    // var config2 = {
    //   method: "POST",
    //   url: "https://ready-shout-30574.botics.co/requestTestimonials/api/requestTestimonials/",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
    //   },
    //   data: JSONed2
    // }

    // axios(config)
    //   .then(resp => {
    //     console.log(resp.data)
    //     alert("Email Send Successfully")

    //     axios(config2)
    //       .then(resp => {
    //         console.log(resp.data)
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
    //     window.location.reload(false)
    //   })
    //   .catch(err => {
    //     console.log(err.message)
    //     alert("An error occured")
    //   })
  }

  console.log(`the state is ${state}`)

  const handleRequests = contact => {
    console.log("hi")
    if (contact.isChecked === true) {
      console.log("isChecked: true", contact)
      let temp_state = [...contacts]
      let temp_element = { ...temp_state[contact.id] }
      temp_element.isChecked = false
      temp_state[contact.id] = temp_element

      // setShareArray([...shareArray, temp_element])

      setContacts(temp_state)

      // let temp_state_2 = [...transferArray]

      // temp_state_2.splice(contact.id, 1)
      // setTransferArray(temp_state_2)

      const temp = [...transferArray]

      const finalArray = temp.filter(item => item.id !== contact.id)

      console.log("finalArray:", finalArray)

      setTransferArray(finalArray)

      return
    } else if (contact.isChecked === false) {
      console.log("huh")
      let temp_state = [...contacts]
      let temp_element = { ...temp_state[contact.id] }
      temp_element.isChecked = true
      temp_state[contact.id] = temp_element
      console.log(temp_state)

      setContacts(temp_state)
      setTransferArray([...transferArray, temp_element])

      // setContacts({
      //   ...contacts[index],
      //   isChecked: true
      // })
    }
  }

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <p
            style={{
              width: "90%",
              marginTop: "40px",
              color: "white",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Work Sans",
              cursor: "pointer"
            }}
            onClick={() => navigation("/campaigns")}
          >
            {"< Request Testimonial"}
          </p>
          <div className="settings-main-short" style={{ height: "670px" }}>
            <p style={{ color: "white", fontSize: "18px", textAlign: "left" }}>
            Request Testimonial Details
            </p>
            <select
              id="campaigns"
              name="Campaigns"
              onChange={e => {
                setState(e.target.value)
                console.log('the selected is', e.target.value)
                let tempState = e.target.value
                let array = campLength.filter((each)=> {
                  return each.VidName === tempState
                })
                setCampID(array[0].key)
              }}
              className="input-sign-new"
              style={{width:'64%'}}
              value={state}
              defaultValue={state}
            >
              <option value="">Select Campaign</option>
              {campLength.map(states => (

                <option key={states.id} value={states.id}>
                  {states.VidName}
                </option>

              ))}
            </select>

            {/* <input
              type="text"
              placeholder="Select a Campaign"
              className="input-sign-new"
              style={{ marginTop: "40px" }}
            /> */}
            {/* <input
              type="text"
              placeholder="Email ID"
              className="input-sign-new"
              onChange={e => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Phone Number"
              className="input-sign-new"
              onChange={e => setPhone(e.target.value)}
            /> */}
            {/* <input
              type="text"
              placeholder="Customer Name"
              className="input-sign-new"
              onChange={e => setCustomerName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company Name"
              className="input-sign-new"
              onChange={e => setCompanyName(e.target.value)}
            /> */}

            <input
              type="text"
              placeholder="Business Name"
              className="input-sign-new"
              onChange={e => setThings((prev)=>({
                ...prev,
                ProjectOwnerName:e.target.value
              }))}
            />
            <input
              type="text"
              placeholder="Sender Name"
              className="input-sign-new"
              onChange={e => setThings((prev)=>({
                ...prev,
                videoGiftReciever:e.target.value
              }))}
            />
            <input
              type="text"
              placeholder="Video Request Message"
              className="input-sign-new"
              onChange={e => setThings((prev)=>({
                ...prev,
                PurposeOFProject:e.target.value
              }))}
            />

            {/* <textarea
              className="input-sign-new"
              style={{ minHeight: "150px" }}
              type="text"
              placeholder="Message"
              onChange={e => setMessage(e.target.value)}
            /> */}



            <button
              className="login-btn-small"
              disabled={loading}
              onClick={() => {
                 if (state === "") {
                  alert("Choose a campaign first")
                }
                else if (things.ProjectOwnerName === "" || things.PurposeOFProject === "" || things.videoGiftReciever === "") {
                  alert("Fill all the required fields")
                }
                else {
                  setOpenContacts(true)
                }
              }}
              style={{ marginBottom: "25px" }}
            >
              {`${loading ? 'Loading':'Send Request'}`}
            </button>
          </div>
        </div>
      </div>

      {/*Contacts modal */}

      <Dialog
        open={openContacts}
        keepMounted
        onClose={() => openContacts(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="modal-main-test">
          <div className="modal-main-test-top">
            <p>Add Contacts</p>
            <p onClick={() => setOpenContacts(false)}>X</p>
          </div>

          <div className="share-modal-middle">
            <img src={SearchImg} />
            <input
              className="share-modal-middle-input"
              type="text"
              placeholder="Search for contacts"
              onChange={e => setContactTxt(e.target.value)}
              value={contactTxt}

              // onChange={e => setFilterTxt(e.target.value)}
              // onFocus={() => setShowContacts(true)}
              //   onBlur={() => setShowContacts(false)}
            />
          </div>

          <div className="scrolled-contacts">
            {contacts
              .filter(contacto =>
                contacto.contactName
                  .toLowerCase()
                  .includes(contactTxt.toLowerCase())
              )
              .map(contact => {
                return (
                  <div className="each-con">
                    <div className="each-con-left">
                      <img src={ProfileImg} />
                      <p style={{ marginLeft: "10px" }}>
                        {contact.contactName}
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      style={{ marginLeft: "20px" }}
                      checked={contact.isChecked}
                      onChange={() => handleRequests(contact)}
                    />
                  </div>
                )
              })}
          </div>

          <div className="bottom-cont">
            <button
              className="login-btn-small"
              onClick={() => {
                if (allSelected === true) {
                  setAllSelected(false)
                  let newContacts = contacts.map(
                    contact => (contact.isChecked = false)
                  )
                  setTransferArray(newContacts)
                } else if (allSelected === false) {
                  let newContacts = contacts.map(
                    contact => (contact.isChecked = true)
                  )
                  setTransferArray(newContacts)

                  setAllSelected(true)
                }
              }}
              style={{ width: "120px" }}
            >
              {`${allSelected ? "Unselect All" : "Select All"} `}
            </button>
            <button
              className="login-btn-small"
              onClick={postTestimonial}
              style={{ width: "120px" }}
            >
              Send Mail
            </button>
            <button
              className="login-btn-small"
              onClick={postSMS}
              style={{ width: "120px" }}
            >
              Send SMS
            </button>
          </div>
        </div>
      </Dialog>

      {/*Success Modal*/}
      <Dialog
        open={successModal}
        keepMounted
        onClose={() => setSuccessModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="success-contact-modal">
          <p>Emails Sent Successfully</p>
          <div className="success-contact-btns">
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={() => setSuccessModal(false)}
            >
              Ok
            </button>
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={() => setSuccessModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={successModal2}
        keepMounted
        onClose={() => setSuccessModal2(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="success-contact-modal">
          <p>SMS Sent Successfully</p>
          <div className="success-contact-btns">
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={() => setSuccessModal2(false)}
            >
              Ok
            </button>
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={() => setSuccessModal2(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={warningModal}
        keepMounted
        onClose={() => setWarningModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="warning-contact-modal">
          <p>
            Following contacts doesn't either have an email address or has a
            badly formatted email
          </p>

          <div
            style={{
              width: "90%",
              paddingLeft: "20px",
              paddingRight: "20px",
              overflowY: "auto",
              height: "300px"
            }}
          >
            {deadContacts.map(dead => {
              return <h5 style={{ color: "white" }}>{dead.contactName}</h5>
            })}
          </div>

          <div className="success-contact-btns">
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={altPost}
            >
              Filter
            </button>
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={() => setWarningModal(false)}
            >
              Skip Contacts
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={warningModal2}
        keepMounted
        onClose={() => setWarningModal2(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="warning-contact-modal">
          <p>
            Following contacts doesn't either have a valid email address or
            phone number
          </p>

          <div
            style={{
              width: "90%",
              paddingLeft: "20px",
              paddingRight: "20px",
              overflowY: "auto",
              height: "300px"
            }}
          >
            {deadContacts.map(dead => {
              return <h5 style={{ color: "white" }}>{dead.contactName}</h5>
            })}
          </div>

          <div className="success-contact-btns">
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={altSMSPost}
            >
              Filter
            </button>
            <button
              className="login-btn-small"
              style={{ width: "120px" }}
              onClick={() => setWarningModal2(false)}
            >
              Skip Contacts
            </button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default RequestTestimonial
