import React, { useState, useEffect, useContext, useRef } from "react"
import "../StaffMembers/StaffMembers.css"
import "./Contacts.css"
import SideMenu from "../SideMenu/SideMenu"
import Header from "../Header/Header"
import DownArrow from "../../assets/images/downarrow.png"
import RightArrow from "../../assets/images/rightarrows.png"
import ProfileImg from "../../assets/images/usernew.png"
import EditImg from "../../assets/images/edit.png"
import BinImg from "../../assets/images/bin.png"
import { useNavigate } from "react-router-dom"
import axios from "axios"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import UploadIcon from "./../../assets/images/upload.png"
import CrossIcon from "./../../assets/images/cross.png"
import DownIcon from "./../../assets/images/download.png"
import UpIcon from "./../../assets/images/bigup.png"
import CSVReader from "react-csv-reader"
import { TokenContext } from "../../context/TokenContext"
import Pagination from "../Components/Pagination"
import { CircularProgress } from "@material-ui/core"
import PhoneInput from "react-phone-input-2"

const Contacts = () => {
  const navigation = useNavigate()
  const [contacts, setContacts] = useState([])
  const [arrayOfBooleans, setArrayOfBooleans] = useState([])
  const [currentUser, setCurrentUser] = useState({})
  const [currentEditUser, setCurrentEditUser] = useState({})
  const [apphendUser, setApphendUser] = useState({})
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [openBulkModal, setOpenBulkModal] = useState(false)
  const [csvData, setCsvData] = useState([])
  const hiddenFileInput = useRef(null)
  const { user, setUser, globalRole, setGlobalRole } = useContext(TokenContext)
  const [contentLoading, setContentLoading] = useState(false)
  console.log("hey user is", user)

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  console.log(globalRole)

  const parseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true
  }

  const errorFileDocu = () => {
    alert("Invalid file data, please upload in correct csv format")
  }

  const handleFileOnly = (data, fileInfo) => {
    console.log(data, fileInfo)
    setCsvData(data)
  }

  const handleFileChange = () => {
    if (csvData.length <= 0) {
      alert("Please provide a valid csv file")
      return
    }
    csvData.forEach((eachData, index) => {
      let stringedObj = { ...eachData }
      let finalObj = {
        ...stringedObj,
        contactNumber: stringedObj.contactNumber.toString(),
        usedID: user.id,
        email: user.email
      }

      let loopData = JSON.stringify(finalObj)

      console.log("finale is", loopData)
      let config = {
        method: "POST",
        url: "https://ready-shout-30574.botics.co/contact/api/Contact/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
        },
        data: loopData
      }

      axios(config)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    })

    alert("Contacts Uploaded")
    window.location.reload()

    setOpenBulkModal(false)
    setOpen2(false)
  }

  console.log(csvData)

  console.log("user's id is", user)

  useEffect(() => {
    setContentLoading(true)
    var config = {
      method: "GET",
      url: "https://ready-shout-30574.botics.co/contact/api/Contact/?limit=1000000000",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data.results)
        setContacts(resp.data.results)

        let filteredTests = resp.data.results.filter(result => {
          return (
            result.email ===
            JSON.parse(localStorage.getItem("@storage_Object")).email
          )
        })

        let tempData = filteredTests

        tempData.forEach(element => {
          element.isOpen = false
        })

        tempData.forEach((element, id) => {
          element.index = id
        })

        tempData.forEach(element => {
          element.isEdit = false
        })

        console.log("temp becomes", tempData)

        setContacts(tempData)
        setContentLoading(false)
        // const arr = Array(resp.data.results.length)
        // arr.fill(false)
        // setArrayOfBooleans(arr)
      })
      .catch(err => {
        console.log(err.message)
        setContentLoading(false)
        alert("An error occured")
      })
  }, [])

  const dropDownSwitch = index => {
    //filter the selected object to update its selected attribute
    console.log("index is", index)

    const elementsIndex = contacts.findIndex(element => element.index === index)
    console.log("index is", elementsIndex)
    let newArray = [...contacts]

    console.log("newArray is", newArray)

    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isOpen: !newArray[elementsIndex].isOpen
    }

    if (newArray[elementsIndex].isEdit) {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        isEdit: false
      }
      let updatedArray = newArray
      setContacts(updatedArray)
    } else {
      let updatedArray = newArray
      setContacts(updatedArray)
    }
  }

  const dropDownEditSwitch = index => {
    const elementsIndex = contacts.findIndex(element => element.index === index)

    let newArray = [...contacts]
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      isEdit: !newArray[elementsIndex].isEdit
    }

    if (newArray[elementsIndex].isOpen) {
      let updatedArray = newArray
      setContacts(updatedArray)
    } else {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        isOpen: true
      }
      let updatedArray = newArray
      setContacts(updatedArray)
    }
  }

  // const selectChange = index => {
  //   //filter the selected object to update its selected attribute

  //   const elementsIndex = members.findIndex(element => element.index == index)
  //   console.log(elementsIndex)
  //   let newArray = [...members]

  //   newArray[elementsIndex] = { ...newArray[elementsIndex], isOpen: true }

  //   let updatedArray = newArray

  //   setMembers(updatedArray)
  // }

  // console.log(staffMembers)

  // const [members, setMembers] = useState([
  //   {
  //     name: "Sarah Johnson",
  //     email: "sarahjohnson@gmail.com",
  //     number: "+123456789",
  //     accessType: "Manager",
  //     isOpen: true,
  //     index: 0
  //   },
  //   {
  //     name: "John Doe",
  //     email: "johndoe@gmail.com",
  //     number: "+156456789",
  //     accessType: "Manager",
  //     isOpen: false,
  //     index: 1
  //   },
  //   {
  //     name: "John Smith",
  //     email: "johnsmith@gmail.com",
  //     number: "+15645456789",
  //     accessType: "Manager",
  //     isOpen: false,
  //     index: 2
  //   }
  // ])

  const handleDeletion = () => {
    var config = {
      method: "DELETE",
      url: `https://ready-shout-30574.botics.co/contact/api/Contact/${currentUser.key}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      }
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        alert("Member Deleted Successfully")

        let uiUpdate = [...contacts]

        const elementsIndex = uiUpdate.findIndex(
          element => element.id === currentUser.id
        )

        uiUpdate.splice(elementsIndex, 1)

        setContacts(uiUpdate)
        setOpen(false)
      })
      .catch(err => {
        console.log(err)
        alert("Failed to Delete staff member")
        setOpen(false)
      })
  }

  const updateStaff = () => {
    let JSONed = JSON.stringify(currentEditUser)
    console.log(currentEditUser)
    var config = {
      method: "PATCH",
      url: `https://ready-shout-30574.botics.co/contact/api/Contact/${currentEditUser.key}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@storage_Key")}`
      },
      data: JSONed
    }

    axios(config)
      .then(resp => {
        console.log(resp.data)
        alert("Member updated Successfully, refresh to see changes")
      })
      .catch(err => {
        console.log(err)
        alert("Failed to Update staff member")
      })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage

  const currentPosts = contacts.slice(indexOfFirstPost, indexOfLastPost)

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber)

  return (
    <>
      {/*Left sidebar */}
      {SideMenu()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {Header()}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "68%",
              alignSelf: "flex-start",
              marginLeft: "6%",
              marginTop: "3%",
              marginBottom: "1%"
            }}
          >
            <p
              style={{
                width: "90%",
                marginTop: "40px",
                color: "white",
                fontSize: "26px",
                fontWeight: "bold",
                fontFamily: "Work Sans",
                textAlign: "left"
              }}
            >
              {`Contacts`}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100px",
                justifyContent: "space-around",
                // opacity: globalRole === "user" ? 0 : 1
                opacity: 1
              }}
            >
              <div
                className="staff-div"
                style={{ cursor: "pointer" }}
                onClick={() => navigation("/add-contact")}
              >
                <p>Add New Contact</p>
              </div>
              <div
                className="staff-div-2"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenBulkModal(true)}
              >
                <img src={UploadIcon} />
                <p style={{ color: "#9d9e9d" }}>Bulk Upload Contacts</p>
              </div>
            </div>
          </div>
          {/*globalRole !== "user" */}
          {true ? (
            <div className="all-staff">
              {contacts.length <= 0 && contentLoading ? (
                <CircularProgress
                  color="white"
                  style={{
                    color: "white",
                    alignSelf: "center",
                    margin: "20px"
                  }}
                />
              ) : contacts.length <= 0 ? (
                <p style={{ textAlign: "center", color: "white" }}>
                  No Contacts Added
                </p>
              ) : (
                currentPosts.map((members, i) => {
                  return (
                    <div className="all-staff-each">
                      <div className="all-staff-each-top">
                        <div className="all-staff-each-top-left">
                          <div className="all-staff-each-top-left-more">
                            {members.isOpen ? (
                              <img
                                src={DownArrow}
                                onClick={() => dropDownSwitch(i)}
                              />
                            ) : (
                              <img
                                src={RightArrow}
                                onClick={() => dropDownSwitch(i)}
                              />
                            )}

                            <img
                              src={
                                members?.contactPhoto
                                  ? members?.contactPhoto?.split("?")[0]
                                  : ProfileImg
                              }
                              style={{
                                height: "45px",
                                width: "45px",
                                borderRadius: "50px"
                              }}
                            />
                          </div>
                          {members.isEdit ? (
                            <input
                              type="text"
                              placeholder={members?.contactName}
                              className="input-sign-new"
                              onChange={e =>
                                setCurrentEditUser({
                                  ...currentEditUser,
                                  contactName: e.target.value
                                })
                              }
                              style={{
                                height: "30px",
                                marginLeft: "20px",
                                marginBottom: "0px",
                                width: "250px"
                              }}
                            />
                          ) : (
                            <p
                              style={{
                                textAlign: "left",
                                width: "50%",
                                color: "white"
                              }}
                            >
                              {members.contactName}
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "50px"
                          }}
                        >
                          <img
                            src={EditImg}
                            onClick={() => {
                              setCurrentEditUser(members)
                              dropDownEditSwitch(i)
                            }}
                          />
                          <img
                            src={BinImg}
                            onClick={() => {
                              setCurrentUser(members)
                              setOpen(true)
                            }}
                          />
                        </div>
                      </div>
                      {members.isOpen ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            width: "40%",
                            alignSelf: "flex-start"
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                              color: "white",
                              marginTop: "20px",
                              alignItems: "center"
                            }}
                          >
                            <p style={{ fontWeight: 700 }}>Email Address</p>
                            {members.isEdit ? (
                              <input
                                type="text"
                                placeholder={members?.contactEmail}
                                className="input-sign-new"
                                style={{
                                  height: "30px",
                                  marginLeft: "20px",
                                  marginBottom: "0px"
                                }}
                                onChange={e =>
                                  setCurrentEditUser({
                                    ...currentEditUser,
                                    contactEmail: e.target.value
                                  })
                                }
                              />
                            ) : (
                              <p
                                style={{
                                  fontSize: "16px",
                                  color: "lightgray",
                                  marginLeft: "50px"
                                }}
                              >
                                {members?.contactEmail || "-"}
                              </p>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                              color: "white",
                              marginTop: "20px",
                              alignItems: "center"
                            }}
                          >
                            <p style={{ fontWeight: 700 }}>Phone Number</p>

                            {members.isEdit ? (
                              // <input
                              //   type="text"
                              //   placeholder={members?.contactNumber}
                              //   className="input-sign-new"
                              //   style={{
                              //     height: "30px",
                              //     marginLeft: "20px",
                              //     marginBottom: "0px"
                              //   }}
                              //   onChange={e =>
                              //     setCurrentEditUser({
                              //       ...currentEditUser,
                              //       contactNumber: e.target.value
                              //     })
                              //   }
                              // />
                              <PhoneInput
                                country={"us"}
                                containerStyle={{
                                  alignSelf: "center",
                                  width: "62%"
                                }}
                                inputStyle={{
                                  width: "100%",
                                  alignSelf: "center",
                                  height: "40px"
                                }}
                                placeholder={members?.contactNumber}
                                // value={details.contactNumber}
                                onChange={phone =>
                                  setCurrentEditUser(prev => ({
                                    ...prev,
                                    contactNumber: phone
                                  }))
                                }
                              />
                            ) : (
                              <p
                                style={{
                                  fontSize: "16px",
                                  color: "lightgray",
                                  marginLeft: "50px"
                                }}
                              >
                                {members?.contactNumber || "-"}
                              </p>
                            )}
                          </div>
                          {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "100%",
                          color: "white",
                          marginTop: "20px",
                          alignItems: "center"
                        }}
                      >
                        <p style={{ fontWeight: 700 }}>Access Type</p>

                        {members.isEdit ? (
                          <select
                            id="campaigns"
                            name="Campaigns"
                            // onChange={e => setDetails({ ...details, role: e.target.value })}
                            onChange={e =>
                              setCurrentEditUser({
                                ...currentEditUser,
                                role: e.target.value
                              })
                            }
                            style={{
                              height: "30px",
                              marginLeft: "20px",
                              marginBottom: "0px"
                            }}
                            className="input-sign-new"
                          >
                            <option value="">Staff Member Access type</option>
                            {roles.map((role, id) => (
                              <option key={id} value={role}>
                                {role}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p
                            style={{
                              fontSize: "16px",
                              color: "lightgray",
                              marginLeft: "50px"
                            }}
                          >
                            {members?.role || "-"}
                          </p>
                        )}
                      </div> */}
                          {members.isEdit ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "50%",
                                color: "white",
                                marginTop: "20px",
                                alignItems: "center",
                                background: "#57b100",
                                borderRadius: "20px",
                                height: "40px",
                                alignSelf: "flex-start",
                                cursor: "pointer"
                              }}
                              onClick={updateStaff}
                            >
                              <p>Change</p>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  )
                })
              )}
            </div>
          ) : (
            <div>
              <p style={{ color: "white", textAlign: "left" }}>
                You do not have the right privilege to access contacts, only an
                admin or moderator can access
              </p>
            </div>
          )}

          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={contacts.length}
            paginate={paginate}
          />
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Contact"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete ${currentUser.contactName}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              console.log(currentUser)
              setOpen(false)
            }}
            color="primary"
          >
            No
          </Button>
          <Button onClick={handleDeletion} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload CSV"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Choose a properly formatted CSV`}
          </DialogContentText>
          <div style={{ marginLeft: "10px", width: "20%" }}>
            <CSVReader
              cssClass="csv-reader-input"
              label=""
              onFileLoaded={handleFileOnly}
              onError={errorFileDocu}
              parserOptions={parseOptions}
              inputStyle={{
                color: "#3b3c3a",
                fontSize: "16px"
              }}
              accept=".csv, text/csv"
              ref={hiddenFileInput}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen2(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFileChange} color="primary" autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openBulkModal} onClose={() => setOpenBulkModal(false)}>
        <div className="main-bulk">
          <div className="bulk-modal">
            <img
              src={CrossIcon}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer"
              }}
              onClick={() => setOpenBulkModal(false)}
            />
            <p className="bulk-modal-p1">Bulk upload contact</p>
            <p style={{ color: "#ddd" }}>
              You can upload more than one customers at a time using a properly
              formatted CSV file, to upload a formatted CSV document you need
              contactName, contactEmail and contactNumber as attributes
            </p>
          </div>

          <div className="bottom-btns">
            <div
              className="bottom-each"
              onClick={() =>
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/ready-shout-f359c.appspot.com/o/username.csv?alt=media&token=895869dc-dc8b-48e1-b722-bcbc61123348"
                )
              }
            >
              <img src={DownIcon} />
              <p
                style={{
                  color: "white",
                  fontWeight: "600",
                  marginLeft: "15px"
                }}
              >
                Download sample CSV
              </p>
            </div>
            <div className="bottom-each" onClick={() => setOpen2(true)}>
              <img src={UpIcon} />
              <p
                style={{
                  color: "white",
                  fontWeight: "600",
                  marginLeft: "15px"
                }}
              >
                Upload CSV
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Contacts
